import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import LocationSearch from '../LocationsSeachBox';
import { useSelector} from 'react-redux';
const PropertyInfo2 = (props) => {
    const configdata = useSelector(state => state.configdata);
  const mapsKey = configdata?.common?.googleMapsKey;
    
    const [addressData,setAddressData] = useState({});
    const navigate = useNavigate();
    const [selectedPlace, setSelectedPlace] = useState(null);
    return (
         
                
                    <div className="cont pi2">
                        {/* <div className="seleted_item">
                            <span className="material-icons-outlined"> done </span> <span className="text">I am  <strong>{props.currentCategory}</strong> </span>  
                        </div> */}
                        {props.properyInfo?.loanfor == 'Purchase' ? <><h3 className="feild_title">{props?.formJSON[1]?.label}</h3>
                        <div className="btn-group2">
                            {props?.formJSON?.[1].options.map((element, index) => (
                                 <button id={element.id} key={index} type="button" className="cp_btn" style={props.properyInfo?.stepIntheProcess === element.value ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} onClick={(e) => {props.handleInputChange('stepIntheProcess', element.value); props.setCurrentCategory('propertySearch');}}>{element.label}</button>
                            ))}
                        </div>
                       </>: 
                       <>
                       <h3 className="feild_title">{props?.formJSON[2]?.label}</h3>
                        <div className="search_block">
                            <div className="search_wrp">
                                <span className="material-icons-outlined">
                                    location_on
                                </span>
                                {mapsKey && 
                                                                <LocationSearch setSelectedPlace={setSelectedPlace} selectedPlace={props?.properyInfo?.PropertyPlace} setAddressData={setAddressData}/> 
                                }
           {/* <button className="search_btn"> */}
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                {/* </button> */}
                            </div>
                        </div></>
                        }
        </div>
    );
};

export default PropertyInfo2;