import React,{useState, useEffect} from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import LocationSearch from './LocationsSeachBox';
import {getOrganization, updateOrganization , getStates} from "./../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import {formatPhoneNumber} from "./../utils/commonUtils.js";
import HomeFooter from './HomeFooter';
import Select from 'react-select';
const Organization = () => {
    const loading = useSelector((state) => state.loaderVisible);
    const [formData, setFormData] = useState({});
    const [organizationForm,setOrganizationForm] = useState([]);
    const [showState,setShowState] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [selectedOfficePlace, setSelectedOfficePlace] = useState(null);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Form successfully submitted.');
    const [initialFormData, setInitialFormData] = useState({});
    const [addressData,setAddressData] = useState({});
    const [officeAddressData,setOfficeAddressData] = useState({});
    const [isEqual , setIsEqual] = useState(false);
    const [states, setStates] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    const stateOptions = states?.map((state) => ({
      value: state,
      label: state,
  }));
  const [statesError,setStatesError] = useState(false)
  const [orgNames,setOrgNames] = useState([]);
  const [inputsMsgError,setInputsMsgError] = useState({});
  const [isChanged,setIsChanged] = useState(false);
  let inputsErr = {};
  

      useEffect(() => {
        getstates()
        getOrganizationData()
          }, []);
        const getOrganizationData = (e) => {
          dispatch(showLoader());
          getOrganization('organization').then(function (response) {
            console.log('getOrganization',response);
            if (response?.result) {
                setFormData({...formData,...response?.result?.[0]});
                setOrganizationForm(response?.result);
                setInitialFormData({ ...response?.result?.[0] }); // Set initial data here
                setSelectedPlace(response?.result?.[0]?.address)
                setSelectedOfficePlace(response?.result?.[0]?.officeAddress);
                const orgNamesList = response?.result?.map(org => org.name);
                setOrgNames(orgNamesList);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
        }

    const handleChange = (e) => {
        let { name, value } = e.target;
        console.log('name, value ',name, value )

        if(name == "name"){
          setIsChanged(!isChanged);
          
        }
        // if(name === "name" && value.includes(' ')){
        //     value = "";
        //     setFormData((prev) => ({
        //         ...prev,
        //         [name]: value,
        //       }));
        // }
        // Regex to allow only numbers and hyphens
        const regex = /^[0-9-]*$/;
        // If the input is for lenderlin, validate it
        if (name === "lenderlin") {
          // Filter the value based on the regex
          value = value.split('').filter(char => regex.test(char)).join('');
        }

       

        setFormData((prev) => ({
            ...prev,
            [name]: value,
          }));
    
        // Validation logic can be added here
        // Example: You can check if the input is empty and show an error message.
        // You can also perform more complex validation based on your requirements.
      };
      const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };


      useEffect(() => {
     
       console.log(' if(selectedPlace){',selectedPlace);
            setFormData((prevData) => {
            // Create a copy of the previous data
            let updatedData = { ...prevData };
            // Update the copied data with the new key-value pair
            updatedData = {
              ...updatedData,
              ['address']: selectedPlace,
            };
        
           
            return updatedData;
          });
        
      }, [selectedPlace]);

      useEffect(() => {
             setFormData((prevData) => {
             // Create a copy of the previous data
             let updatedData = { ...prevData };
             // Update the copied data with the new key-value pair
             updatedData = {
               ...updatedData,
               ['officeAddress']: selectedOfficePlace,
             };
            
             return updatedData;
           });
         
       }, [selectedOfficePlace]);

      const isValidPhoneNumber = (phoneNumber) => {
        // Implement a basic phone number validation logic here
        // Example: Check if the phone number contains only digits and common separators
        const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return phoneRegex.test(phoneNumber);
      };
     

      const addErrorClass = (field) => {
        const inputElement = document.querySelector(`[name=${field}]`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
        }
      };

      const removeError = (e) => {
        //let field = e.target.name;
        let field;
        if (typeof e === 'string') {
            field = e;
        } else if (e && e.target) {
            field = e.target.name;
        }
        const inputElement = document.querySelector(`[name=${field}]`);
        if (inputElement && inputElement.classList.contains('invalid-input')) {
          inputElement.classList.remove('invalid-input');
        }
      };

      useEffect(() => {
        if(addressData){
          
          removeError("address");
        }
      },[formData?.address]) 
      useEffect(()=>{
        console.log(formData)
      },[formData])

      const validateInputs = () => {
        const checkEmptyValues = /^\s+$/;
        inputsErr = {};
        //const faxregex = /^[0-9-]+$/;
        const requiredFields = [
            'name',
            // 'phone',
            // 'address',
            // 'fax',
            // 'states',
            // 'officeAddress',
            // 'mailingAddress',
            // 'licenseNumber',
            // 'linNumber'
          ];
      
          let isValid = true;
      
        // Function to add error className to input
        

       
      
        // // Remove error classes on all inputs
        // document.querySelectorAll('.invalid-input').forEach((input) => {
        //   input.classList.remove('invalid-input');
        // });

        // const searchWrpElement = document.querySelector(".search_wrp");

        // if (searchWrpElement) {
        //         searchWrpElement.classList.remove("invalid-input");
        // }
      
        // Validate required fields
        for (const field of requiredFields) {
            // if(field == 'name'){
            //     console.log('formData[field].trim()',formData[field].trim() !== '');
            // }
            // if ((field === 'name') && (!formData[field] || checkEmptyValues.test(formData[field]))){
            //     isValid = false;
            //     addErrorClass(field);
            // }
            if (field === 'name') {
              if (!formData[field] || checkEmptyValues.test(formData[field])) {
                  isValid = false;
                  addErrorClass(field);
              } else if (formData[field] && formData[field] !== initialFormData?.name) {
                // Only check for uniqueness if the name has changed
                  if (orgNames.includes(formData[field].trim())) {
                      isValid = false;
                      addErrorClass(field);
                      inputsErr[field] = "Name Already Exists!";
                  }
                }
            }

            if(field === 'address' && (!selectedPlace )){
                isValid = false;
                addErrorClass(field);
            }

            if(field === 'officeAddress' && (!selectedOfficePlace )){
              isValid = false;
              addErrorClass(field);
            }

            if ((field === 'licenseNumber') && (!formData[field] || checkEmptyValues.test(formData[field]))){
              isValid = false;
              addErrorClass(field);
            }

            if ((field === 'mailingAddress') && (!formData[field] || checkEmptyValues.test(formData[field]))){
              isValid = false;
              addErrorClass(field);
            }

            if ((field === 'lenderlin') && (!formData[field] || checkEmptyValues.test(formData[field]))){
              isValid = false;
              addErrorClass(field);
            }

            // if (!formData[field] || formData[field].includes(' ')) {
            //     isValid = false;
            //   addErrorClass(field);
          
            // } 
          
          if ((field === 'phone') && !isValidPhoneNumber(formData[field])) {
            // Validate phone number format
            isValid = false;
            addErrorClass(field);
          }
          if((field ==='states') && formData[field]?.length === 0){
            isValid = false;
            setStatesError(true)
          }
          
          if(field === 'fax' && (!formData[field] || checkEmptyValues.test(formData[field]) ||  formData[field]?.length > 10)){
            isValid = false;
            addErrorClass(field);
          }

         
        }

        setInputsMsgError(inputsErr);
        return isValid;
      };

       const handleSubmit = (e) => {
        e.preventDefault();
        // Your form submission logic goes here
        const isValid = validateInputs();

        console.log('formDatavalidateInputs',isValid);
        console.log('formData',formData);
        if (isValid) {
         
          delete formData._id;
          dispatch(showLoader());
          updateOrganization(formData).then(function (response) {
            setPopupOpen(true);
            if(response?.result == "incorrect old password"){
                setmessage(response?.result)
            }else{
               
                setmessage("Changes updated successfully.")
            }
            dispatch(hideLoader());
            // getOrganizationData();

           
            //setShowState(false);
            console.log('responseresponse',response);
          }).catch((err) => {
            console.error.bind("Login error", err);
            dispatch(hideLoader());
          })
        }
        
      };

      const closePopup = () => {
        setPopupOpen(false);
        setShowState(false);
        getOrganizationData();
        
      };

      const hanldeBack = ()=>{
        setShowState(false)
      }
      const handleEdit = (data)=>{
        console.log("dataedit", data)
        setShowState(true)
        setFormData(data);
        setInitialFormData(data); // Set initial data here
        setSelectedPlace(data?.address)
        setSelectedOfficePlace(data?.officeAddress)
      }
      const handleSelectChange =(selectedOptions) => {
        setFormData({...formData, states: selectedOptions.map(option => option.value) });
      };

      const hasFormChanged = () => {
         console.log("old",initialFormData)
         console.log("new",formData);

         
            // return !Object.keys(initialFormData).every(key => initialFormData[key] === formData[key]) || initialFormData.address !== selectedPlace;
            return Object.keys(initialFormData).every(key => initialFormData[key] === formData[key]);
         
      };

      // const hasFormChanged = () => {
      //   console.log("old", initialFormData);
      //   console.log("new", formData);
    
      //   // Check if any key in formData doesn't exist in initialFormData
      //   const keysInFormData = Object.keys(formData);
      //   const keysInInitialData = Object.keys(initialFormData);
    
      //   // Return true if any key in formData doesn't exist in initialFormData
      //   if (keysInFormData.some(key => !keysInInitialData.includes(key))) {
      //       return false;
      //   }
    
      //   // Check for changes in existing keys
      //   return !keysInInitialData.every(key => {
      //       return formData[key] === initialFormData[key];
      //   });
      // };
    

      useEffect(() => {
          setInputsMsgError({});
          const equal = hasFormChanged();
          console.log('is equal',equal, officeAddressData);
          setIsEqual(equal);
      },[formData,addressData, officeAddressData])

    return (
        
        <div id="layout-wrapper">
 {loading ? <Loader/> : null} 
    {isPopupOpen && !loading && (
        <PopupMessage

          type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
          message={popupMessage}
          onClose={closePopup}
          
        />
      )}

            <MainHeader />
            <SideMenu />
            <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">
               
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Organization</h4>
                                {showState && 
                                <a href="#" id="navigateOrganizationBack" className="back_btn" onClick={(e) => {e.preventDefault();hanldeBack();}}><span className="material-icons icon"> chevron_left
                                </span>BACK</a> }
                                
                            </div>
                            <div className="card">
                            

                                <div className="card-body my_account">
                                {(!showState &&  organizationForm?.length > 0) &&

                                
                                      <table className="table db_table">

                                      <thead className="table-light">
                                          <tr>
                                              <th width="20%">Name</th>
                                              <th width="20%">Phone no</th>
                                              {/* <th width="20%">Address</th> */}
                                              <th width="20%">State</th>
                                              {/* <th width="20%">Fax Number</th> */}
                                              <th width="20%">Mailing Address</th>
                                              <th width="20%">Actions</th>
                                          </tr>
                                      </thead>
  
                                      <tbody>
                                        {organizationForm?.map((data,index)=>{
                                          return(
                                      <tr key={index}>
                                                      <td>{data?.name}</td>
                                                      <td>{data?.phone}</td>
                                                      {/* <td>{data?.address}</td> */}
                                                      <td>{data?.states?.join(', ')}</td>
                                                      {/* <td>{data?.fax}</td> */}
                                                      <td>{data?.mailingAddress}</td>
                                                      <td>
                                                          <div className="btn-group">
                                                              <button id="editOrganization" type="button" className="btn btn-outline-secondary" onClick={() => {handleEdit(data);}}><span
                                                                      className="material-symbols-outlined icon tooltip-container"  ><span className="tooltip">Edit</span>  edit
                                                                  </span></button>
                                                                 
                                                          </div>
                                                      </td>
                                                  </tr>
                                            )
                                          })}
  
                                        </tbody>
                                        </table>
                                      }
                                      {showState && 
                                       <div className='account_form user_info'>
                                       <div className="row">
                                        
                                       
                                       <div className="col-md-6">
                                       <div className="row">
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Name </label>
                                                           <input id="organizationName" type="text" value={formData.name} name="name" className="form-control capitalize-text"
                                                               placeholder='Easy Street Capital, LLC' onChange={(e)=>handleChange(e)} onFocus={(e) => removeError(e)}/>
                                                            {inputsMsgError && inputsMsgError?.name && <p style={{color:'red'}}>{inputsMsgError?.name}</p>}
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Phone Number </label>
                                                           <input id="organizationPhone" placeholder="(123) 456-7890"  onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}  
                                                           onChange={(e)=>handleChange(e)} value={formData.phone} type="tel" name="phone" className="form-control"  
                                                           pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890" onFocus={(e) => removeError(e)}/>
                                                       </div>
                                                   </div>
                                               </div>
                                               {/* <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Address </label>
                                                           {/* <input onChange={handleChange} type="text" name="organisationaddress" className="form-control"
                                                               placeholder='Search City and Zip Code' /> */}
                                                                {/* <LocationSearch name={'address'} setAddressData={setAddressData}  setSelectedPlace={setSelectedPlace} selectedPlace={selectedPlace} /> */}
                                                       {/* </div>
                                                   </div>
                                               </div> */}
                                               {/* <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Fax Number </label>
                                                           <input id="organizationFax" onChange={(e)=>handleChange(e)} value={formData.fax} type="number" name="fax" className="form-control" placeholder='Fax' onFocus={(e) => removeError(e)}/>
                                                       </div>
                                                   </div>
                                               </div> */}
                                               
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Office Address </label>
                                                           {mapsKey &&
                                                           <LocationSearch name={'officeAddress'} setAddressData={setOfficeAddressData}  setSelectedPlace={setSelectedOfficePlace} selectedPlace={selectedOfficePlace} />
                                                           }
                                                           {/* <input id="organizationOfficeAddress" onChange={(e)=>handleChange(e)} value={formData.officeAddress} type="text" name="officeAddress" className="form-control" placeholder='Office Address' onFocus={(e) => removeError(e)}/> */}
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Mailing Address</label>
                                                           <input id="organizationMailingAddress" onChange={(e)=>handleChange(e)} value={formData.mailingAddress} type="text" name="mailingAddress" className="form-control" placeholder='Mailing Address' onFocus={(e) => removeError(e)}/>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>License Number</label>
                                                           <input id="organizationLicenseNumber" onChange={(e)=>handleChange(e)} value={formData.licenseNumber} type="number" name="licenseNumber" className="form-control" placeholder='License Number' onFocus={(e) => removeError(e)}/>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label> Lender EIN</label>
                                                           <input id="organizationLIN" onChange={(e)=>handleChange(e)} value={formData.lenderlin} type="text" name="lenderlin" className="form-control" placeholder='Enter the Lender EIN' onFocus={(e) => removeError(e)}/>
                                                       </div>
                                                   </div>
                                               </div>
                                               
                                               <div className="col-md-12">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>States</label>
                                                           <Select
                                                           id="organizationStates"
                                                           name='states'
                                                          //  onFocus={(e) => removeError(e)}
                                                            isMulti
                                            options={stateOptions}
                                            placeholder="Select"
                                            value={formData?.states?.map((value) => ({
                                              value,
                                                label: value
                                                })) || []}
                                                className={`w-100 ${statesError && 'invalid-input'}`}
                                            onFocus={()=>{setStatesError(false)}}
                                            onChange={handleSelectChange}
                                        />
                                                       </div>
                                                   </div>
                                               </div>
                                               {/* <div className="col-md-3">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Domain</label>
                                                           <input onChange={handleChange} type="text" name="organisationdomain" className="form-control"
                                                               placeholder='Enter Domain' />
                                                       </div>
                                                   </div>
                                               </div>
   
                                               <div className="col-md-6">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Managers</label>
                                                           <input onChange={handleChange} type="text" name="organisationmanager" className="form-control" placeholder='' />
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-md-3"></div> */}
                                               {/* <div className="col-md-3 user_management user_info">
                                                   <div className="uploads">
                                                       <div className="item">
                                                           <div className="left">
                                                               <img src="assets/images/icons/mode-portrait.svg"
                                                                   className="img" />
                                                               <div>
                                                                   <h4>Logo</h4>
                                                                   <p>Upload clear Picture</p>
   
                                                               </div>
                                                           </div>
                                                           <a href="#" className="upload"> <span className="material-icons icon">
                                                                   file_upload </span> </a>
                                                       </div>
                                                   </div>
   
                                               </div> */}
                                           </div>
                                       </div>
                                       <div className="col-md-3">
                                       <label>Logo </label>
                                           <div className='organization_logo'>
                                           
                                           <img src={formData?.logo} />
                                           </div>
                                        
                                       </div>
                                           </div>
                                        
                                           <div className="form-group btns mt-10">
                                               <button id="updateOrganization" type="button" onClick={(e)=> {handleSubmit(e)}} className='solid_btn' style = {{'opacity' : (isEqual === true) ? '0.6' : '1'}}  disabled = {(isEqual === true)}>UPDATE</button>
                                           </div>
   
                                       </div>
                                      }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default Organization;