import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import BorrowerInfo2 from '../BorrowerInfo/BorrowerInfo2';
import BorrowerInfo3 from '../BorrowerInfo/BorrowerInfo3';
import BorrowerInfo4 from '../BorrowerInfo/BorrowerInfo4';
import BorrowerInfo5 from '../BorrowerInfo/BorrowerInfo5';
import BorrowerInfo6 from '../BorrowerInfo/BorrowerInfo6';
import BorrowerInfo7 from '../BorrowerInfo/BorrowerInfo7';
import { useLoanInfo } from '../../utils/loanInfoContext';
import { useUserType } from '../../utils/UserTypeContext';
import LocationSearch from '../LocationsSeachBox';
import { getallUsers, lookupsCallForPipeline , getWarmAppEmailDetails} from "../../utils/reducer";
import { formatPhoneNumber } from '../../utils/commonUtils';
import { useSelector} from 'react-redux';
import CurrencyInput from 'react-currency-input-field';

const BorrowerInfo = () => {
  const configdata = useSelector(state => state.configdata);
  const mapsKey = configdata?.common?.googleMapsKey;
    let formElementsbrockerJson = [
        {
          "type": "text",
          "label": "Broker First Name",
          "placeholder": "Enter first name",
          "name": "brokerfirstname",
          "id" : "brokerFirstName"
        },
        {
          "type": "text",
          "label": "Broker Last Name",
          "placeholder": "Enter last name",
          "name": "brokerlastname",
          "id" : "brokerLastName"
        },
        {
          "type": "text",
          "label": "Broker Email",
          "placeholder": "Enter email",
          "name": "brokeremail",
          "id" : "brokerEmail"
        },
        {
          "type": "text",
          "label": "Broker Phone#",
          "placeholder": "(xxx) xxx-xxxx",
          "name": "brokerphonenumber",
          "pattern": "\(\d{3}\) \d{3}-\d{4}",
          "id" : "brokerPhone"
        },
        {
          "type": "number",
          "label": "Broker Points",
          "placeholder": "Enter points",
          "name": "brokerpoints",
          "id" : "brokerPoints"
        },
        {
          "type": "text",
          "label": "Broker Processing Fee",
          "placeholder": "Enter processing fee",
          "name": "brokerprocessingfee",
          "id" : "brokerProcessingFee"
        },
        {
          "type": "text",
          "label": "Borrower First Name",
          "placeholder": "Enter borrower first name",
          "name": "borrowerfirstname",
          "id" : "brokerBorrowerFirstName"
        },
        {
          "type": "text",
          "label": "Borrower Last Name",
          "placeholder": "Enter borrower last name",
          "name": "borrowerlastname",
          "id" : "brokerBorrowerLastName"
        },
        {
          "type": "text",
          "label": "Borrower Email",
          "placeholder": "Enter borrower email",
          "name": "borroweremail",
          "id" : "brokerBorrowerEmail"
        },
        {
          "type": "text",
          "label": "Borrower Phone",
          "placeholder": "(xxx) xxx-xxxx",
          "name": "borrowerphone",
          "pattern": "\(\d{3}\) \d{3}-\d{4}",
          "id" : "brokerBorrowerPhone"
        },
        
        {
          "type": "text",
          "label": "What is the Borrower's Primary Occupation?",
          "placeholder": "Enter occupation",
          "name": "borroweroccupation",
          "description": "(I.E. Nurse, Realtor, General Contractor, Full-Time Investor, Etc)",
          "id" : "brokerOccupation"
        },
        {
          "type": "text",
          "label": "What is the Borrower's Credit Score?",
          "placeholder": "Enter credit score",
          "name": "creditscore",
          "id" : "brokerCreditScore"
        },
        {
          "type": "text",
          "label": "What Are the Borrower's Current Liquid Cash Reserves?",
          "placeholder": "Enter liquid cash reserves",
          "name": "liquidcashreserve",
          "description": "(Bank Accounts, Stocks, Etc)",
          "id" : "brokerLiquidCashReserve"
        },
        {
          "type": "number",
          "label": "How Many Investment Properties Has the Borrower Purchased and Renovated in the Last 2 Years?",
          "placeholder": "Enter occupation",
          "name": "investmentproperties",
          "description": "(HUD Required)",
          "id" : "brokerInvestmentProperties"
        },
        {
          "type": "dropdown",
          "label": "Are you currently working with an Easy Street Account Executive?",
          "name": "accountexecutive",
          "options": [
            { "value": "", "label": "Please Select Account Executive" },
            { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
            { "value": "AJ Weed", "label": "AJ Weed" },
            { "value": "Alex Fernandez", "label": "Alex Fernandez" },
            { "value": "Allison Stuart", "label": "Allison Stuart" },
            { "value": "Amanda Hart", "label": "Amanda Hart" },
            { "value": "Antony Riveros", "label": "Antony Riveros" },
            { "value": "Austin Walker", "label": "Austin Walker" },
            { "value": "Barbara Taylor", "label": "Barbara Taylor" },
            { "value": "Bill Fay", "label": "Bill Fay" },
            { "value": "Brad Nichols", "label": "Brad Nichols" },
            { "value": "Brittany Mullen", "label": "Brittany Mullen" },
            { "value": "Casey Denton", "label": "Casey Denton" },
            { "value": "Cat Rauh", "label": "Cat Rauh" },
            { "value": "Collin Duffy", "label": "Collin Duffy" },
            { "value": "Dan Gamache", "label": "Dan Gamache" },
            { "value": "Dustin Gayer", "label": "Dustin Gayer" },
            { "value": "Gabe Correll", "label": "Gabe Correll" },
            { "value": "Gray Cato", "label": "Gray Cato" },
            { "value": "Jack Mawer", "label": "Jack Mawer" },
            { "value": "Gabe Correll", "label": "Gabe Correll" },
            { "value": "Jack Tulloch", "label": "Jack Tulloch" },
            { "value": "Jamie Talley", "label": "Jamie Talley" },
            { "value": "Jarell Keys", "label": "Jarell Keysl" },
            { "value": "Jason Strom", "label": "Jason Strom" },
            { "value": "John Chong", "label": "John Chong" },
            { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
            { "value": "Josh Sweat", "label": "Josh Sweat" },
            { "value": "Joshua Brandt", "label": "Joshua Brandt" },
            { "value": "Kara Manie", "label": "Kara Manie" },
            { "value": "Kelly Smith", "label": "Kelly Smith" },
            { "value": "Kevin Beaver", "label": "Kevin Beaver" },
            { "value": "Luis Florentino", "label": "Luis Florentino" },
            { "value": "Luis Morales", "label": "Luis Morales" },
            { "value": "Marlon Wilson", "label": "Marlon Wilson" },
            { "value": "Matthew Boggs", "label": "Matthew Boggs" },
            { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
            { "value": "Michael Armona", "label": "Michael Armona" },
            { "value": "Nick Venuto", "label": "Nick Venuto" },
            { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
            { "value": "Reed Morley", "label": "Reed Morley" },
            { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
            { "value": "Scott Townes", "label": "Scott Townes" },
            { "value": "Sean Waller", "label": "Sean Waller" },
            { "value": "Stephanie Vega", "label": "Stephanie Vega" },
            { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
            { "value": "Stephen King", "label": "Stephen King" },
            { "value": "Tanner Lewis", "label": "Tanner Lewis" },
            { "value": "Tanner Macklin", "label": "Tanner Macklin" },
            { "value": "Taylor Florentino", "label": "Taylor Florentino" },
            { "value": "Todd Russell", "label": "Todd Russell" },
            { "value": "Tony Gamboa", "label": "Tony Gamboa" },
            { "value": "Tracey Autry", "label": "Tracey Autry" },
            { "value": "Tyler Ingel", "label": "Tyler Ingel" },
            { "value": "Zach Edelman", "label": "Zach Edelman" },
            { "value": "Zachary  Deal", "label": "Zachary  Deal" }
          ],
          "id" : "brokerAccountExecutive"
        },
        {
          "type": "dropdown",
          "label": "Where Did You Hear About Us?",
          "name": "referredBy",
          "options": [
            { "value": "", "label": "Select" },
            { "value": "CIX", "label": "CIX" },
            { "value": "Conference", "label": "Conference" },
            { "value": "Events", "label": "Events" },
            { "value": "Organic Website Search", "label": "Organic Website Search" },
            { "value": "Other", "label": "Other" },
            { "value": "Referral", "label": "Referral" },
            { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
            { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
            { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
            { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
            { "value": "Social Media - X", "label": "Social Media - X" },
          ],
          "id" : "brokerReferredBy"
        },
        {
          "type": "address",
          "label": "Borrower Home Address",
          "placeholder": "Enter address",
          "name": "borroweraddress",
          "id" : "brokerAddress"
        },
        {
          "type": "buttonGroup",
          "label": "Is Borrower an Entity?",
          "options": [
            { "value": "Yes", "label": "Yes" ,"id" : "brokerEntityYes"},
            { "value": "No", "label": "No" ,"id" : "brokerEntityNo"},
          ],
          "name": "isborroweranentity"
        },
        {
          "type": "text",
          "label": "Fill in Entity Name",
          "placeholder": "Fill in Entity Name",
          "name": "entityname",
          "id" : "brokerEntityName"
        },
      ]
    
      
      let formElementsborrowerJson = [
        {
          "type": "text",
          "label": "Borrower First Name",
          "placeholder": "Enter borrower first name",
          "name": "borrowerfirstname",
          "id" : "borrowerFirstName"
        },
        {
          "type": "text",
          "label": "Borrower Last Name",
          "placeholder": "Enter borrower last name",
          "name": "borrowerlastname",
          "id" : "borrowerLastName"
        },
        {
          "type": "text",
          "label": "Borrower Email",
          "placeholder": "Enter borrower email",
          "name": "borroweremail",
          "id" : "borrowerEmail"
        },
        {
          "type": "text",
          "label": "Borrower Phone",
          "placeholder": "(xxx) xxx-xxxx",
          "name": "borrowerphone",
          "pattern": "\(\d{3}\) \d{3}-\d{4}",
          "id" : "borrowerPhone"
        },
        {
          "type": "text",
          "label": "What is the borrower's primary occupation?",
          "placeholder": "What is the borrower's primary occupation?",
          "name": "borroweroccupation",
          "description": "(Nurse, realtor, general contractor, full-time investor, etc)",
          "id" : "borrowerOccupation"
        },
        {
          "type": "number",
          "label": "How many investment properties has the borrower purchased and renovated in the last 2 years?",
          "placeholder": "How many investment properties has the borrower purchased and renovated in the last 2 years?",
          "name": "investmentproperties",
          "description" : "(hud required)",
          "id" : "borrowerInvestmentProperties"
        },
        {
          "type": "text",
          "label": "What is Your Estimated Credit Score?",
          "placeholder": "Enter credit score",
          "name": "creditscore",
          "id" : "borrowercreditScore"
        },
        {
          "type": "text",
          "label": "What Are the Borrower's Current Liquid Cash Reserves?",
          "placeholder": "Enter liquid cash reserves",
          "name": "liquidcashreserve",
          "description": "(Bank Accounts, Stocks, Etc)",
          "id" : "borrowerLiquidCashReserve"
        },
        {
          "type": "dropdown",
          "label": "Are you currently working with an Easy Street Account Executive?",
          "name": "accountexecutive",
          "options": [
            { "value": "", "label": "Please Select Account Executive" },
            { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
            { "value": "AJ Weed", "label": "AJ Weed" },
            { "value": "Alex Fernandez", "label": "Alex Fernandez" },
            { "value": "Allison Stuart", "label": "Allison Stuart" },
            { "value": "Amanda Hart", "label": "Amanda Hart" },
            { "value": "Antony Riveros", "label": "Antony Riveros" },
            { "value": "Austin Walker", "label": "Austin Walker" },
            { "value": "Barbara Taylor", "label": "Barbara Taylor" },
            { "value": "Bill Fay", "label": "Bill Fay" },
            { "value": "Brad Nichols", "label": "Brad Nichols" },
            { "value": "Brittany Mullen", "label": "Brittany Mullen" },
            { "value": "Casey Denton", "label": "Casey Denton" },
            { "value": "Cat Rauh", "label": "Cat Rauh" },
            { "value": "Collin Duffy", "label": "Collin Duffy" },
            { "value": "Dan Gamache", "label": "Dan Gamache" },
            { "value": "Dustin Gayer", "label": "Dustin Gayer" },
            { "value": "Gabe Correll", "label": "Gabe Correll" },
            { "value": "Gray Cato", "label": "Gray Cato" },
            { "value": "Jack Mawer", "label": "Jack Mawer" },
            { "value": "Gabe Correll", "label": "Gabe Correll" },
            { "value": "Jack Tulloch", "label": "Jack Tulloch" },
            { "value": "Jamie Talley", "label": "Jamie Talley" },
            { "value": "Jarell Keys", "label": "Jarell Keysl" },
            { "value": "Jason Strom", "label": "Jason Strom" },
            { "value": "John Chong", "label": "John Chong" },
            { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
            { "value": "Josh Sweat", "label": "Josh Sweat" },
            { "value": "Joshua Brandt", "label": "Joshua Brandt" },
            { "value": "Kara Manie", "label": "Kara Manie" },
            { "value": "Kelly Smith", "label": "Kelly Smith" },
            { "value": "Kevin Beaver", "label": "Kevin Beaver" },
            { "value": "Luis Florentino", "label": "Luis Florentino" },
            { "value": "Luis Morales", "label": "Luis Morales" },
            { "value": "Marlon Wilson", "label": "Marlon Wilson" },
            { "value": "Matthew Boggs", "label": "Matthew Boggs" },
            { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
            { "value": "Michael Armona", "label": "Michael Armona" },
            { "value": "Nick Venuto", "label": "Nick Venuto" },
            { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
            { "value": "Reed Morley", "label": "Reed Morley" },
            { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
            { "value": "Scott Townes", "label": "Scott Townes" },
            { "value": "Sean Waller", "label": "Sean Waller" },
            { "value": "Stephanie Vega", "label": "Stephanie Vega" },
            { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
            { "value": "Stephen King", "label": "Stephen King" },
            { "value": "Tanner Lewis", "label": "Tanner Lewis" },
            { "value": "Tanner Macklin", "label": "Tanner Macklin" },
            { "value": "Taylor Florentino", "label": "Taylor Florentino" },
            { "value": "Todd Russell", "label": "Todd Russell" },
            { "value": "Tony Gamboa", "label": "Tony Gamboa" },
            { "value": "Tracey Autry", "label": "Tracey Autry" },
            { "value": "Tyler Ingel", "label": "Tyler Ingel" },
            { "value": "Zach Edelman", "label": "Zach Edelman" },
            { "value": "Zachary  Deal", "label": "Zachary  Deal" },
          ],
          "id" : "borrowerAccountExecutive"
        },
        {
          "type": "dropdown",
          "label": "Where Did You Hear About Us?",
          "name": "referredBy",
          "options": [
            { "value": "", "label": "Select" },
            { "value": "CIX", "label": "CIX" },
            { "value": "Conference", "label": "Conference" },
            { "value": "Events", "label": "Events" },
            { "value": "Organic Website Search", "label": "Organic Website Search" },
            { "value": "Other", "label": "Other" },
            { "value": "Referral", "label": "Referral" },
            { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
            { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
            { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
            { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
            { "value": "Social Media - X", "label": "Social Media - X" },
          ],
          "id" : "borrowerReferredBy"
        },
        {
          "type": "address",
          "label": "Borrower Home Address",
          "placeholder": "Enter address",
          "name": "borroweraddress",
          "id" : "borrowerAddress"
        },
        {
          "type": "buttonGroup",
          "label": "Is Borrower an Entity?",
          "options": [
            { "value": "Yes", "label": "Yes" ,"id" : "borrowerEntityYes"},
            { "value": "No", "label": "No" ,"id" : "borrowerEntityNo"},
          ],
          "name": "isborroweranentity"
        },
        {
          "type": "text",
          "label": "Fill in Entity Name",
          "placeholder": "Fill in Entity Name",
          "name": "entityname",
          "id" : "borrowerEntityName"
        },
        
       
       
        
      ]; 

      let coBorrowerJson = [
        {
            "type": "text",
            "label": "Co-Borrower First Name",
            "placeholder": "Enter Co borrower first name",
            "name": "coborrowerfirstname",
            "id" : "coborrowerFirstName"
          },
          {
            "type": "text",
            "label": "Co-Borrower Last Name",
            "placeholder": "Enter Co borrower last name",
            "name": "coborrowerlastname",
            "id" : "coborrowerLastName"
          },
          {
            "type": "text",
            "label": "Co-Borrower Email",
            "placeholder": "Enter Co borrower email",
            "name": "coborroweremail",
            "id" : "coborrowerEmail"
          },
          {
            "type": "text",
            "label": "Co-Borrower Phone",
            "placeholder": "(xxx) xxx-xxxx",
            "name": "coborrowerphone",
            "pattern": "\(\d{3}\) \d{3}-\d{4}",
            "id" : "coborrowerPhone"
          },
          {
            "type": "text",
            "label": "What is the Co-Borrower's primary occupation?",
            "placeholder": "What is the borrower's primary occupation?",
            "name": "coborroweroccupation",
            "description": "(Nurse, realtor, general contractor, full-time investor, etc)",
            "id" : "coborrowerOccupation"
          },
          {
            "type": "number",
            "label": "How many investment properties has the Co-Borrower purchased and renovated in the last 2 years?",
            "placeholder": "How many investment properties has the Co-Borrower purchased and renovated in the last 2 years?",
            "name": "coborroweinvestmentproperties",
            "description" : "(hud required)",
            "id" : "coborrowerInvestmentProperties"
          },
          {
            "type": "text",
            "label": "What is Your Estimated Credit Score?",
            "placeholder": "Enter credit score",
            "name": "coborrowercreditscore",
            "id" : "coborrowercreditScore"
          },
          {
            "type": "text",
            "label": "What Are the Co-Borrower's Current Liquid Cash Reserves?",
            "placeholder": "$0.00",
            "name": "coborrowerliquidcashreserve",
            "description": "(Bank Accounts, Stocks, Etc)",
            "id" : "coborrowerLiquidCashReserve"
          },
          // {
          //   "type": "dropdown",
          //   "label": "Are you currently working with an Easy Street Account Executive?",
          //   "name": "coborroweaccountexecutive",
          //   "options": [
          //     { "value": "", "label": "Please Select Account Executive" },
          //     { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
          //     { "value": "AJ Weed", "label": "AJ Weed" },
          //     { "value": "Alex Fernandez", "label": "Alex Fernandez" },
          //     { "value": "Allison Stuart", "label": "Allison Stuart" },
          //     { "value": "Amanda Hart", "label": "Amanda Hart" },
          //     { "value": "Antony Riveros", "label": "Antony Riveros" },
          //     { "value": "Austin Walker", "label": "Austin Walker" },
          //     { "value": "Barbara Taylor", "label": "Barbara Taylor" },
          //     { "value": "Bill Fay", "label": "Bill Fay" },
          //     { "value": "Brad Nichols", "label": "Brad Nichols" },
          //     { "value": "Brittany Mullen", "label": "Brittany Mullen" },
          //     { "value": "Casey Denton", "label": "Casey Denton" },
          //     { "value": "Cat Rauh", "label": "Cat Rauh" },
          //     { "value": "Collin Duffy", "label": "Collin Duffy" },
          //     { "value": "Dan Gamache", "label": "Dan Gamache" },
          //     { "value": "Dustin Gayer", "label": "Dustin Gayer" },
          //     { "value": "Gabe Correll", "label": "Gabe Correll" },
          //     { "value": "Gray Cato", "label": "Gray Cato" },
          //     { "value": "Jack Mawer", "label": "Jack Mawer" },
          //     { "value": "Gabe Correll", "label": "Gabe Correll" },
          //     { "value": "Jack Tulloch", "label": "Jack Tulloch" },
          //     { "value": "Jamie Talley", "label": "Jamie Talley" },
          //     { "value": "Jarell Keys", "label": "Jarell Keysl" },
          //     { "value": "Jason Strom", "label": "Jason Strom" },
          //     { "value": "John Chong", "label": "John Chong" },
          //     { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
          //     { "value": "Josh Sweat", "label": "Josh Sweat" },
          //     { "value": "Joshua Brandt", "label": "Joshua Brandt" },
          //     { "value": "Kara Manie", "label": "Kara Manie" },
          //     { "value": "Kelly Smith", "label": "Kelly Smith" },
          //     { "value": "Kevin Beaver", "label": "Kevin Beaver" },
          //     { "value": "Luis Florentino", "label": "Luis Florentino" },
          //     { "value": "Luis Morales", "label": "Luis Morales" },
          //     { "value": "Marlon Wilson", "label": "Marlon Wilson" },
          //     { "value": "Matthew Boggs", "label": "Matthew Boggs" },
          //     { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
          //     { "value": "Michael Armona", "label": "Michael Armona" },
          //     { "value": "Nick Venuto", "label": "Nick Venuto" },
          //     { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
          //     { "value": "Reed Morley", "label": "Reed Morley" },
          //     { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
          //     { "value": "Scott Townes", "label": "Scott Townes" },
          //     { "value": "Sean Waller", "label": "Sean Waller" },
          //     { "value": "Stephanie Vega", "label": "Stephanie Vega" },
          //     { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
          //     { "value": "Stephen King", "label": "Stephen King" },
          //     { "value": "Tanner Lewis", "label": "Tanner Lewis" },
          //     { "value": "Tanner Macklin", "label": "Tanner Macklin" },
          //     { "value": "Taylor Florentino", "label": "Taylor Florentino" },
          //     { "value": "Todd Russell", "label": "Todd Russell" },
          //     { "value": "Tony Gamboa", "label": "Tony Gamboa" },
          //     { "value": "Tracey Autry", "label": "Tracey Autry" },
          //     { "value": "Tyler Ingel", "label": "Tyler Ingel" },
          //     { "value": "Zach Edelman", "label": "Zach Edelman" },
          //     { "value": "Zachary  Deal", "label": "Zachary  Deal" },
          //   ],
          //   "id" : "coborrowerAccountExecutive"
          // },
          // {
          //   "type": "dropdown",
          //   "label": "Where Did You Hear About Us?",
          //   "name": "coborrowereferredBy",
          //   "options": [
          //     { "value": "", "label": "Select" },
          //     { "value": "CIX", "label": "CIX" },
          //     { "value": "Conference", "label": "Conference" },
          //     { "value": "Events", "label": "Events" },
          //     { "value": "Organic Website Search", "label": "Organic Website Search" },
          //     { "value": "Other", "label": "Other" },
          //     { "value": "Referral", "label": "Referral" },
          //     { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
          //     { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
          //     { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
          //     { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
          //     { "value": "Social Media - X", "label": "Social Media - X" },
          //   ],
          //   "id" : "coborrowerReferredBy"
          // },
          {
            "type": "address",
            "label": "Co-Borrower Home Address",
            "placeholder": "Enter address",
            "name": "coborroweraddress",
            "id" : "coborrowerAddress"
          },
          {
            "type": "buttonGroup",
            "label": "Is Co-Borrower an Entity?",
            "options": [
              { "value": "Yes", "label": "Yes" ,"id" : "CoBorrowerEntityYes"},
              { "value": "No", "label": "No" ,"id" : "CoBorrowerEntityNo"},
            ],
            "name": "iscoborroweranentity"
          },
          {
            "type": "text",
            "label": "Fill in Entity Name",
            "placeholder": "Fill in Entity Name",
            "name": "coborrowerentityname",
            "id" : "coborrowerEntityName"
          },
          
         
         
      ];

    const navigate = useNavigate();
    const [currentPage , setCurrentPage] = useState(1);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });
    const { useType, setUseType } = useUserType();
    const [errorMsg,setErrorMsg] = useState({});
    const [formJSON, setformjson] = useState(useType == 'broker' ? formElementsbrockerJson : formElementsborrowerJson);
    const { loanInfo, setLoanInfo } = useLoanInfo();
    console.log('loanInfoloanInfo',loanInfo);
    const [borrowerInfoOneData , setBorrowerInfoOneData] = useState(loanInfo?.borrowerinfo ? loanInfo?.borrowerinfo : {});
    const [showInput,setShowInput] = useState(false);
    const [usersOptions, setUsers] = useState([]);
    const [referralSource,setRefferalSource] = useState([]);
    let floatFields = ['brokerprocessingfee','liquidcashreserve']
    let errors = {};
    
    //co Borrower states
    const [showCoBorrower,setShowCoBorrower] = useState(false);
    const [showCoBorrowerEntity,setShowCoBorrowerEntity] = useState(false);
    const [coBorrowerData,setCoBorrowerData] = useState(loanInfo?.coBorrower || {});
    const [coBorrowerFlag,setCoBorrowerFlag] = useState(loanInfo?.coborrowerflag || false);
    const [coBorrowerErrors,setCoborrowerErrors] = useState({});
    const [locationName, setLocationName] = useState('');
  
    const [coBorrowerSelectedPlace, setCoBorrowerSelectedPlace] = useState(null);
    const [coBorrowerAddressData, setCoBorrowerAddressData] = useState({});
    const [sessionUserId,setSessionUserId] = useState(null);
    const [userTypeApi,setUserTypeAPI] = useState(null);
    
    let coErrors = {};
    let payload = {
      "usertype" : "",
      "emailid" : ""
    }
    

    useEffect(() => {
      const isStrategyEmpty = Object.keys(loanInfo.strategyinfo).length === 0;
      if (isStrategyEmpty) {
        navigate('/strategyinfo');
      } 
      if(loanInfo?.borrowerinfo?.isborroweranentity === 'Yes'){
        setShowInput(true);
    }
    }, [loanInfo]);
    const loandropdown = async (num) => {
      lookupsCallForPipeline(num).then(function (response) {
          if (response?.result) {
              console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
              let data = response?.result?.data[0]?.lookupOptions;
              const options = data.map(option => ({
                value: `${option.option}`,
                label: `${option.option}`,
              })); 
              options.unshift({ value: "", label: "Select" });
              setRefferalSource(options);
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
  };

    useEffect(() => {
      getallUsers('loselectable').then(function (response) {
          console.log('alert',response?.result?.data);
          if (response?.result?.data) {
            const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
            // Map activeUsers to options array
            const options = activeUsers.map(user => ({
              value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
              label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
              accountexecutiveid : user?.userid
            }));
            options.unshift({ value: "Not working with anyone currently", label: "Not working with anyone currently" });
            
            options.unshift({ value: "", label: "Please select account executive" });
            console.log('sdfsfdoptions',options);

            setUsers(options);
          }
        })
        .catch((err) => {
          console.error.bind("getallUsers error", err);
        })
        loandropdown(10013)
    }, []);

    useEffect(() => {
     
      if(selectedPlace){
        setBorrowerInfoOneData((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
          // Update the copied data with the new key-value pair
          let selectedAddress ={
            'borroweraddress' : addressData?.address?.trim() || addressData?.fullAddress,
            'borrowerstate' : addressData?.state,
            'borrowerzipcode' : addressData?.zipcode,
            'borrowergeolocation' : addressData?.geolocation,
            'borrowergooglemaplink' : addressData?.url,
            'borrowerarea': addressData?.area,
            'borrowercity': addressData?.city,
            'borrowerfulladdress': addressData?.fullAddress,
            'borrowerstateshort': addressData?.stateShortName,
            'borrowercounty': addressData?.county,
          }
          updatedData = {
            ...updatedData,
            ...selectedAddress,
          };
      
         
          return updatedData;
        });
      }
    }, [selectedPlace]);

    useEffect(() => {
      if (coBorrowerSelectedPlace) {
          setCoBorrowerData((prevData) => ({
              ...prevData,
              coborroweraddress: coBorrowerAddressData?.address?.trim() || coBorrowerAddressData?.fullAddress,
              coborrowerstate: coBorrowerAddressData?.state,
              coborrowerzipcode: coBorrowerAddressData?.zipcode,
              coborrowergeolocation: coBorrowerAddressData?.geolocation,
              coborrowergooglemaplink: coBorrowerAddressData?.url,
              coborrowerarea: coBorrowerAddressData?.area,
              coborrowercity: coBorrowerAddressData?.city,
              coborrowerfulladdress: coBorrowerAddressData?.fullAddress,
              coborrowerstateshort: coBorrowerAddressData?.stateShortName,
              coborrowercounty: coBorrowerAddressData?.county,
          }));
      }
  }, [coBorrowerSelectedPlace]);

    const formatNumber = (value) => {
     
        const numericValue = value.replace(/[^\d.]/g, '');
        const formattedValue = `$${parseFloat(numericValue).toLocaleString()}`;
        value = (formattedValue === "$NaN") ? '' : formattedValue;
  
        return value;
    };

    const handleFloatInputs = (name,value)=>{
        
      console.log(value)
      floatFields.forEach(key => {
        if(key === name){
          let updatedValue = isNaN(value) ? '' : value;
          setBorrowerInfoOneData((prevData) => {
            // Create a copy of the previous data
            let updatedData = { ...prevData };
            // Update the copied data with the new key-value pair
            updatedData = {
              ...updatedData,
              // [key]: value?.replace(/\s+/g, ' '),
              [key]: updatedValue,
            };
        
          
            return updatedData;
          });
        }
      })
      
    
  }

    const handleInputChange = (e) => {
          e.preventDefault();
          // Update the formData with the new key-value pair
        let key = e.target.name;
        let value = e.target.value;
        console.log('here vallll',value?.replace(/\s+/g, ' '));
        const allowedKeys = ['liquidcashreserve', 'brokerprocessingfee'];
        if (allowedKeys.includes(key) && value !== '') {
        
           
            // const numericValue = value.replace(/[^\d.]/g, '');
            // const formattedValue = `$${parseFloat(numericValue).toLocaleString()}`;
            // value = (formattedValue === "$NaN") ? '' : formattedValue;
            value = formatNumber(value);
            
           
          
              
        }

        const allowedTextInputKeys = ['borrowerfirstname','borroweroccupation',
        'borrowerlastname','borroweroccupation','brokerfirstname','brokerlastname','entityname'];//text inputs
        const textregex = /^[A-Za-z\s]+$/;
        if (allowedTextInputKeys.includes(key) && value !== '' && key !=="entityname" && key !== "borroweroccupation") {
          
          // Remove numeric characters
          let removedNumbers = !(textregex.test(value)) ? '' : value;
          // let formattedValueText = `$${removedNumbers}`;

          value = removedNumbers;
          
        }else if(key ==="entityname" && allowedTextInputKeys.includes(key)){
              value = (value?.trim() === "" ? '' : value);
        }else if(key === "borroweroccupation" && allowedTextInputKeys.includes(key)){
            
            // Apply the regex pattern based on the field
            value = value;
        }

   const allowedNumbersTypeKeys = ['investmentproperties','creditscore','experience'];
   const allowThreeDigitOnly = /^\d{0,3}$/;
   if(allowedNumbersTypeKeys.includes(key) && value !== ''){
      let formattedNumberValue = !(allowThreeDigitOnly.test(value)) ? '' : value;
      value = formattedNumberValue;
   }

   // Prevent treating 0 as a falsy value
  if (key === 'investmentproperties' && value === '0') {
    value = '0';
  }

  //  const allowedPhoneInputKeys = ['borrowerphone','brokerphonenumber'];//text inputs
  //  const mobileregex = /^\d{10}$/;
  //  if (allowedPhoneInputKeys.includes(key) && value !== '') {
    
  //    // Remove numeric characters
  //   let isValidNumber  = !(mobileregex.test(value)) ? '' : value;
  //   // let formattedValueText = `$${removedNumbers}`;

  //   value = isValidNumber;
    
  //  }

   if (parseFloat(value) < 0) {
    // If it's negative, set the input value to an empty string or handle it as needed
    value = '';
  }

    if(key == "borroweremail"){
      setUserTypeAPI("BORROWER");
    }else if(key == "brokeremail"){
      setUserTypeAPI("BROKER");
    }
     setBorrowerInfoOneData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        if(key==='accountexecutive'){
          const matchedUser = usersOptions?.find(user => user.value === value);
          console.log('matchedUser',matchedUser,value)
          updatedData = {
            ...updatedData,
            [key]: value,
            'accountexecutiveid' : matchedUser?.accountexecutiveid ? matchedUser?.accountexecutiveid : '',
          };
        }else{
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            // [key]: value?.replace(/\s+/g, ' '),
            [key]: value,
          };
        }
        
    
      
        return updatedData;
      });
    };


  const handleButtonChange = (key, value) => {
      if(value === "Yes"){
        setShowInput(true);//showing the input tag
      }else{
        setShowInput(false);
      }
    // Update the formData with the new key-value pair
    setBorrowerInfoOneData((prevData) => {
      // Create a copy of the previous data
      let updatedData = { ...prevData };
      // Update the copied data with the new key-value pair
      updatedData = {
        ...updatedData,
        [key]: value,
      };
     
      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        borrowerinfo: updatedData,
      }));
  
      return updatedData;
    });
};
const isValidEmail = (email) => {
  // Implement your email validation logic here
  // Example: Check if the email follows a standard format
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
  let emailtest = emailRegex.test(email);
  return emailtest;
};

const isValidPhoneNumber = (phoneNumber) => {
  // Implement a basic phone number validation logic here
  // Example: Check if the phone number contains only digits and common separators
  const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  // const phoneRegex = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
 
  // const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  let phonetest = phoneRegex.test(phoneNumber);
  // console.log("phone",phonetest);
  return phonetest;
};

//remove errro class
const removeErrorClass = (field) => {
  const inputElement = document.querySelector(`[name=${field}]`);
  if (inputElement) {
    inputElement.classList.remove('invalid-input');
  }
};

const addErrorClass = (field) => {
  const inputElement = document.querySelector(`[name=${field}]`);
  if (inputElement) {
    inputElement.classList.add('invalid-input');
  }
};


const validateInputs = () => {
  errors = {};

  const requiredFields = formJSON
    .filter((element) => element.type !== 'buttonGroup' && element.type !== 'dropdown')
    .map((element) => element.name);

  let isValid = true;

  // Function to add error class to input
  // const addErrorClass = (field) => {
  //   const inputElement = document.querySelector(`[name=${field}]`);
  //   if (inputElement) {
  //     inputElement.classList.add('invalid-input');
  //   }
  // };

  
  // Remove error classes on all inputs
  document.querySelectorAll('.invalid-input').forEach((input) => {
    if(input){
      input.classList.remove('invalid-input');
    }
    
  });

  const searchWrpElement = document.querySelector(".search_wrp");
  if (searchWrpElement) {
          searchWrpElement.classList.remove("invalid-input");
  }

  // Validate required fields
  for (const field of requiredFields) {
      console.log("rquired field",requiredFields);
      if(borrowerInfoOneData['isborroweranentity'] === 'No') {
        isValid = true
            // console.log(borrowerInfoOneData)
      }

      
        
        // if(borrowerInfoOneData['isborroweranentity'] === 'Yes' && borrowerInfoOneData['entityname'] === ''){
        //   isValid = false;
        //   addErrorClass(field);
        // }
      
  
      else if (field !== "borroweraddress" && !borrowerInfoOneData[field]) {
        
        
        //console.log('isValidhere-2',isValid);
        //console.log("address",borrowerInfoOneData[field]);
        // if (field == "borroweraddress" && !borrowerInfoOneData[field]) {
         
        //   const searchWrpElement = document.querySelector(".search_wrp");

        //   if (searchWrpElement) {
        //     isValid = false;
        //     searchWrpElement.classList.add("invalid-input");
        //   }
        // }else{
          addErrorClass(field);
        // }
      }
      else{
        //if(field != "borroweraddress"){
          //isValid = false;
          //addErrorClass(field);
        //} 
        removeErrorClass(field); 
      }
    
    // if ((field === 'borroweremail' && !isValidEmail(borrowerInfoOneData[field])) || (field === 'brokeremail' && !isValidEmail(borrowerInfoOneData[field]))) {
      //   // Validate email format
      //   isValid = false;
      //   addErrorClass(field);
      // }
    
      // if ((field === 'borrowerphone' && !isValidPhoneNumber(borrowerInfoOneData[field]) || field === 'brokerphonenumber') && !isValidPhoneNumber(borrowerInfoOneData[field])) {
      //   // Validate phone number format
      //   isValid = false;
      //   addErrorClass(field);
      // }
  }

  // Validate button groups
  formJSON.filter((element) => element.type === 'buttonGroup').forEach((element) => {
    const selectedButton = borrowerInfoOneData[element.name];
    if (!selectedButton) {
      isValid = false;
      console.log('isValidhere-1',isValid);
      addErrorClass(element.name);
    }
  });

  // Validate dropdowns
  formJSON.filter((element) => element.type === 'dropdown').forEach((element) => {
    const selectedOption = borrowerInfoOneData[element.name];
    if (!selectedOption || selectedOption?.trim() === '') {
      isValid = false;
      console.log('isValidhere0',isValid);
      addErrorClass(element.name);
    }
  });

  // Validate number type
  // formJSON.filter((element) => element.type === 'number').forEach((element) => {
    
  //   if(element.name == "brokerpoints" && useType == 'broker' && !borrowerInfoOneData["brokerpoints"]){
  //     isValid = false;
  //     addErrorClass('brokerpoints');
  //   }else if(element.name != "brokerpoints" && !borrowerInfoOneData[element.name]){
  //     isValid = false;
  //     addErrorClass(element.name);
  //   }else{
  //     removeErrorClass(element.name);
  //   }
  // });
  // Modify the existing validation for number types
  formJSON.filter((element) => element.type === 'number').forEach((element) => {
    const value = borrowerInfoOneData[element.name];
    
    // Check if value is undefined or empty string, but allow 0
    if (element.name === "brokerpoints" && useType === 'broker' && (value === undefined || value === '')) {
      isValid = false;
      addErrorClass('brokerpoints');
    } else if (element.name !== "brokerpoints" && (value === undefined || value === '')) {
      isValid = false;
      addErrorClass(element.name);
    } else {
      removeErrorClass(element.name);
    }
  });


  //validate texts
  formJSON.filter((element) => element.type === 'text').forEach((element) => {
    const selectedText = borrowerInfoOneData[element.name];
    let trimmedText = selectedText // Trim leading and trailing spaces
    if (typeof trimmedText === 'string') {
      trimmedText = trimmedText.trim();
    }
    if(element.name === 'entityname' && borrowerInfoOneData['isborroweranentity'] === 'Yes' && (borrowerInfoOneData['entityname'] === "" || borrowerInfoOneData['entityname'] === undefined)){
        isValid = false;
       addErrorClass(element.name);
    }
    if(!trimmedText && element.name != 'entityname'){
      console.log('isValidhere1',element.name,isValid);
      isValid = false;
      addErrorClass(element.name);
    }
    // if ((element.name === 'borroweremail') &&(!selectedText || !isValidEmail(selectedText))) {
    //   isValid = false;
    //   console.log('isValidhere2',isValid);
    //   addErrorClass(element.name);
    // }

    // if ((element.name === 'brokeremail') &&(!selectedText || !isValidEmail(selectedText))) {
    //   isValid = false;
    //   console.log('isValidhere3',isValid);
    //   addErrorClass(element.name);
    // }

    if ((element.name === 'borroweremail') &&(!selectedText)) {
      isValid = false;
      console.log('isValidhere2',isValid);
      addErrorClass(element.name);
      
    }

    if ((element.name === 'borroweremail') &&(selectedText && !isValidEmail(selectedText))) {
      isValid = false;
      console.log('isValidhere2',isValid);
      addErrorClass(element.name);
      errors[element?.name] = "Invalid Email !";
    }

    if ((element.name === 'brokeremail') &&(!selectedText)) {
      isValid = false;
      console.log('isValidhere3',isValid);
      addErrorClass(element.name);
    }

    if ((element.name === 'brokeremail') &&(selectedText && !isValidEmail(selectedText))) {
      isValid = false;
      console.log('isValidhere3',isValid);
      addErrorClass(element.name);
      errors[element?.name] = "Invalid Email !";
    }

    // if ((element.name === 'borrowerphone') && (!selectedText || !isValidPhoneNumber(selectedText))) {
    //   isValid = false;
    //   console.log('isValidhere4',isValid);
    //   addErrorClass(element.name);
    // }

    // if ((element.name === 'brokerphonenumber') && (!selectedText || !isValidPhoneNumber(selectedText))) {
    //   isValid = false;
    //   console.log('isValidhere5',isValid);
    //   addErrorClass(element.name);
    // }

    if ((element.name === 'borrowerphone') && (!selectedText)) {
      isValid = false;
      console.log('isValidhere4',isValid);
      addErrorClass(element.name);
    }

    if ((element.name === 'borrowerphone') && (selectedText && !isValidPhoneNumber(selectedText))) {
      isValid = false;
      console.log('isValidhere4',isValid);
      addErrorClass(element.name);
      errors[element?.name] = "Phone Number is Invalid .";
    }

    if ((element.name === 'brokerphonenumber') && (!selectedText)) {
      isValid = false;
      console.log('isValidhere5',isValid);
      addErrorClass(element.name);
    }

    if ((element.name === 'brokerphonenumber') && (selectedText && !isValidPhoneNumber(selectedText))) {
      isValid = false;
      console.log('isValidhere5',isValid);
      addErrorClass(element.name);
      errors[element?.name] = "Phone Number is Invalid .";
    }
    
     
  });

  if(borrowerInfoOneData['brokeremail'] !== '' && borrowerInfoOneData['brokeremail'] !== undefined && borrowerInfoOneData['borroweremail'] !== '' && borrowerInfoOneData['borroweremail'] !== undefined && borrowerInfoOneData['brokeremail'] === borrowerInfoOneData['borroweremail']){
    isValid = false;
    addErrorClass('brokeremail');
    addErrorClass('borroweremail');
    // errors['brokeremail'] = "Please enter a different email address .";
    errors['borroweremail'] = "Please enter a different email address .";
    
    console.log('isValidhere6',isValid);
  }

  if(borrowerInfoOneData['brokerphonenumber'] !== '' && borrowerInfoOneData['brokerphonenumber'] !== undefined && borrowerInfoOneData['borrowerphone'] !== '' && borrowerInfoOneData['borrowerphone'] !== undefined && borrowerInfoOneData['borrowerphone'] === borrowerInfoOneData['brokerphonenumber']){
    isValid = false;
    addErrorClass('brokerphonenumber');
    addErrorClass('borrowerphone');
    // errors['brokerphonenumber'] = "Please enter a different phone number .";
    errors['borrowerphone'] = "Please enter a different phone number .";
    
    console.log('isValidhere7',isValid);
  }

  
  if (!borrowerInfoOneData["borroweraddress"] || selectedPlace?.trim() === "") {
         
    const searchWrpElement = document.querySelector(".search_wrp");

    if (searchWrpElement) {
      isValid = false;
      searchWrpElement.classList.add("invalid-input");
    }
  }

  // if(useType == 'broker' && !borrowerInfoOneData["brokerpoints"]){
  //   isValid = false;
  //   addErrorClass('brokerpoints');
  // }

  setErrorMsg(errors);
  return isValid;
};

  //co borrower validation
  const validateCoBorrowerInputs = () => {
    coErrors = {}; // Reset errors at the start

    const requiredFields = [
        'coborrowerfirstname', 
        'coborrowerlastname', 
        'coborroweremail', 
        'coborrowerphone',
       
    ];

    let isValid = true;

    // Validate required fields
    requiredFields.forEach(field => {
        if (!coBorrowerData[field]) {
            isValid = false;
            addErrorClass(field); // Function to add error class
        } else {
            removeErrorClass(field);
        }
    });

    // Email validation
    if(coBorrowerData.coborroweremail && !isValidEmail(coBorrowerData.coborroweremail)){
        isValid = false;
        addErrorClass('coborroweremail');
    }

    // Phone validation
    if(coBorrowerData.coborrowerphone && !isValidPhoneNumber(coBorrowerData.coborrowerphone)) {
        isValid = false;
        addErrorClass('coborrowerphone');
    }

    //co borrower entity
    if(coBorrowerData?.iscoborroweranentity === 'Yes' && !coBorrowerData?.coborrowerentityname){
      isValid = false;
      addErrorClass('coborrowerentityname');
    }

    setCoborrowerErrors(coErrors); // Save errors to state if needed
    return isValid; // Return overall validity
  };

 


  const submitBorrowerInfo = () => {
    console.log("borrower info one data",borrowerInfoOneData)
    const isValid = validateInputs();
    let validCoBorrower = '';

    if (coBorrowerData["coBorrowerExists"] && coBorrowerData["coBorrowerExists"] == 'yes') {
      validCoBorrower = showCoBorrower ? validateCoBorrowerInputs() : true;
      
    }else{
      validCoBorrower = true;
    }
    
    // else{
    //   addErrorClass('coBorrowerYes');
    //   addErrorClass('coBorrowerNo');
    // }

    console.log('isValidisValid',isValid);
    console.log('coborrowervalid',validCoBorrower);
    //co borrower validation

    console.log('co borrower data',coBorrowerData);
    if (isValid && Object.keys(errors)?.length === 0 && validCoBorrower) {
      
      const updatedBorrowerInfo = { ...borrowerInfoOneData };

      // if (showCoBorrower) {
      //   loanInfo.coborrower = coBorrowerData; // Include co-borrower data
      // }

      // Check if the borrower is not an entity and remove the entityname key if necessary
      if (updatedBorrowerInfo?.isborroweranentity === "No" && updatedBorrowerInfo?.hasOwnProperty('entityname')) {
        delete updatedBorrowerInfo.entityname;
      }
      // Save co-borrower information

      if(loanInfo.coborrower?.CoBorrowerEntityYes == 'no' && loanInfo.coborrower?.hasOwnProperty('coborrowerentityname')){
        delete loanInfo.coborrower.CoBorrowerEntityYes;
      }

      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        borrowerinfo: updatedBorrowerInfo,
      }));
      navigate('/submitapp');

    }
  };
    useEffect(() => {
        if(borrowerInfoOneData){
            setLoanInfo((prevLoanInfo) => ({
                ...prevLoanInfo,
                borrowerinfo: borrowerInfoOneData,
              }));
        }
      
      }, [borrowerInfoOneData]);




    const handlePreviousBorrowerPage = () => {
       setCurrentPage(currentPage - 1);
    }

        //pass div to header
        let width = 100;
        let setWidth = '';
        if(currentPage === 1){
            // setWidth = Math.floor(width/7) + '%'; 
            setWidth = 0 + '%';
            // console.log(setWidth);
        }else if(currentPage === 2){
            setWidth = Math.floor(width/7 * 2) + '%'; 
            // console.log(setWidth);
        }else if(currentPage === 3){
            setWidth = Math.floor(width/7 * 3) + '%'; 
            // console.log(setWidth);
        }else if(currentPage === 4){
            setWidth = Math.floor(width/7 * 4) + '%'; 
            // console.log(setWidth);
        }else if(currentPage === 5){
            setWidth = Math.floor(width/7 * 5) + '%'; 
            // console.log(setWidth);
        }else if(currentPage === 6){
            setWidth = Math.floor(width/7 * 6) + '%'; 
            // console.log(setWidth);
        }else if(currentPage === 7){
            setWidth = Math.floor(width/7 * 7) + '%';
            // console.log(setWidth);
        }else{
            setWidth = 0 + '%';
        }


    const getBorrowerPage = (currentPage) => {
        switch(currentPage){
            case 1:
                return <BorrowerInfo setColor={setWidth}/>
            case 2:
                return <BorrowerInfo2 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/> 
            case 3:
                return <BorrowerInfo3 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/>
            case 4:
                return <BorrowerInfo4 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/>
            case 5:
                return <BorrowerInfo5 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/>
            case 6:
                return <BorrowerInfo6 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/>
            case 7:
                return <BorrowerInfo7 setColor={setWidth} currentPage={currentPage} setCurrentPage={setCurrentPage} borrowerInfoOneData = {borrowerInfoOneData} setBorrowerInfoOneData = {setBorrowerInfoOneData} onPrevious={handlePreviousBorrowerPage}/>
            default:
                return <BorrowerInfo setColor={setWidth}/>
        }
    }

    //styles
    const paragraphStyle = {
        color : '#FF0000'
    }

    const handleFocus = (e) => {
      removeErrorClass(e.target.name);
      setErrorMsg({...errorMsg,[e.target.name] : ''});
    }

    /* co borrower section*/
    
    // Initialize coBorrower flag and data
    useEffect(() => {
      if (showCoBorrower) {
          setCoBorrowerFlag(true); // Set flag to true
      } else {
          setCoBorrowerFlag(false); // If not showing, set it to false
      }
      setLoanInfo(prev => ({
          ...prev,
          coborrowerflag: showCoBorrower, // Provide the state directly
          coBorrower: showCoBorrower ? coBorrowerData : {} // Set to either current co-borrower data or an empty object
      }));
  }, [showCoBorrower, coBorrowerData]);
  

  const handleCoBorrowerFloatInputs = (name,value)=>{
        
    // console.log(value)
    const floatInputs = ['coborrowerliquidcashreserve'];
    floatInputs.forEach(key => {
      if(key === name){
        let updatedValue = isNaN(value) ? '' : value;

        setCoBorrowerData(prev => ({ ...prev, [name]: updatedValue  }));
        setLoanInfo(prev => ({
            ...prev,
            coBorrower: {
                ...prev.coBorrower,
                [name]: updatedValue 
            }
        }))
        
      }
    })
    
  
  }

    const handleCoBorrowerChange = (e) => {

      const {name ,value} = e.target;
      
      let validatedValue = value;
      //removeErrorClass(name);

      //text inputs validation
      const textInputs = ['coborrowerfirstname','coborrowerlastname','coborroweroccupation'];

      //numeric inputs
      const numericInputs = ['coborrowercreditscore','coborroweinvestmentproperties'];

      //flat and dollar values
      const floatInputs = ['coborrowerliquidcashreserve'];

      if(textInputs?.includes(name)  && value  !== '' && name !== 'coborroweroccupation'){
        // Remove all non-alphabetic characters
        validatedValue = value.replace(/[^a-zA-Z]/g, '');
        // Prevent non-alphabetic character input by forcefully setting the state
        if (validatedValue !== value) {
            e.target.value = validatedValue; // Set the input value to the validated value
        }
      }else if(name === 'coborroweroccupation' && textInputs?.includes(name)  && value  !== '' ){
        validatedValue = value;
      }

      if(numericInputs?.includes(name) && value  !== ''){
         // Ensure the value only contains digits and has a maximum length of 3
         validatedValue = value.replace(/[^0-9]/g, '').slice(0, 3);

         // Prevent non-numeric character input by forcefully setting the state
         if (validatedValue !== value) {
             e.target.value = validatedValue; // Set the input value to the validated value
         }
      }

      
      // if (floatInputs.includes(name) && value !== '') {
      //   validatedValue = formatNumber(value);
      //   // Set the formatted value back to the input
      //   e.target.value = validatedValue;
           
      // }
      if(name === "coborroweremail"){
        setUserTypeAPI("COBORROWER");
      }
      setCoBorrowerData(prev => ({ ...prev, [name]: validatedValue  }));
      setLoanInfo(prev => ({
          ...prev,
          coBorrower: {
              ...prev.coBorrower,
              [name]: validatedValue 
          }
      }))

    }

    const handleCoBorrowerLoan = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        
        if(name == 'coBorrowerYes'){
            setShowCoBorrower(true);
            value = 'yes';
        }else{
            setShowCoBorrower(false);
            value = 'no';
            // Clear co-borrower data if "No" is selected
            //setCoBorrowerData({ ...coBorrowerData,['coBorrowerExists']: value });
            //coErrors = {}; // Clear errors
        } 

        if(value){
          removeErrorClass('coBorrowerYes');
          removeErrorClass('coBorrowerNo');
        }

    

        setCoBorrowerData(prev => ({ ...prev, ['coBorrowerExists']: value  }));
        //setCoBorrowerData({ ...coBorrowerData,['coBorrowerExists']: value });
        setLoanInfo(prev => ({
            ...prev,
            coBorrower: {
                ...prev.coBorrower,
                ['coBorrowerExists']: value 
            }
        }))
        
        setCoborrowerErrors(coErrors);
        

    }

    const handleCoBorrowerEntity = (e) => {
        let name  = e.target.name;
        let value = e.target.value;
        
        if(name == 'CoBorrowerEntityYes'){
          setShowCoBorrowerEntity(true);
          value = 'Yes';
        }else{
          setShowCoBorrowerEntity(false);
          value = 'No';
          
          
        }
        setCoBorrowerData(prev => {
          // Remove coborrowerentityname if coBorrowerEntity is 'no'
          const newCoBorrowerData = { ...prev, iscoborroweranentity: value };
          if (value === 'No') {
              delete newCoBorrowerData.coborrowerentityname;
          }
          return newCoBorrowerData;
      });
  
      setLoanInfo(prev => {
          const newCoBorrower = { ...prev.coBorrower, iscoborroweranentity: value };
          // Remove coborrowerentityname if coBorrowerEntity is 'no'
          if (value === 'No') {
              delete newCoBorrower.coborrowerentityname;
          }
          return { ...prev, coBorrower: newCoBorrower };
      });
    }


    const handleCoBorrowerFocus = (e) => {
      removeErrorClass(e.target.name);
      setCoborrowerErrors((prevErrors) => ({
          ...prevErrors,
          [e.target.name]: ''
      }));
  };

  useEffect(() => {
    if (coBorrowerData['coBorrowerExists'] && coBorrowerData['coBorrowerExists'] === 'yes') {
      setShowCoBorrower(true);
    } else {
      setShowCoBorrower(false); // Ensure it is set to false here too
      setCoBorrowerData({}); // Reset co-borrower data if no co-borrower is present
    }

  }, [currentPage]);
  

  const handleExpandChange = (param) => {
    //const name = e.target.name;
    let value = '';
    
    if(param == 'up'){
        setShowCoBorrower(true);
        value = 'yes';
    }else{
        setShowCoBorrower(false);
        value = 'no';
        // Clear co-borrower data if "No" is selected
        setCoBorrowerData({});  // Resetting the co-borrower data
    }

    // if(value){
    //   removeErrorClass('coBorrowerYes');
    //   removeErrorClass('coBorrowerNo');
    // }

    if(value == 'no'){
      coErrors = {};
      
    }
    setCoBorrowerData(prev => ({ ...prev, ['coBorrowerExists']: value  }));
    setLoanInfo(prev => ({
        ...prev,
        coBorrower: {
            ...prev.coBorrower,
            ['coBorrowerExists']: value 
        }
    }))
    setCoborrowerErrors(coErrors);
  }

  //call get Borrower , coborrower , broker 
  const getEmailDetails = (data) => {
    if(data.usertype === "COBORROWER"){
        data.usertype = "BORROWER"
    }
    getWarmAppEmailDetails(data).then(function (response) {
        if (response?.result) {
          console.log("borrower email res ",response?.result)
            let matchedApplicant = null;
            let matchedEntityBorrower = null;
            let matchedEntityCoBorrower = {};
            let matchedBroker = null;

            // Iterate through the result array to find a matching email
           
            for (const item of response?.result?.applicant) {
                if (item.borroweremail === data.emailid) {
                 
                    matchedApplicant = item;
                    console.log("matched allpicant",item)
                    break;
                }else{
                  continue;
                }
            }

            if (response?.result?.entity) {
              if (response?.result?.entity?.length === 1) {
                // This else will trigger if the array is empty
                if (userTypeApi === "BORROWER") {
                    matchedEntityBorrower = response?.result?.entity[0];
                   
                } else if (userTypeApi === "COBORROWER") {
                    matchedEntityCoBorrower = response?.result?.entity[0];
                    console.log('matchedEntityCoBorrower',matchedEntityCoBorrower)
                }
              }
              else if (response?.result?.entity?.length > 0) {
                  matchedEntityBorrower = response?.result?.entity[0]; // First entity as borrower
                  if (response?.result?.entity?.length > 1) {
                      matchedEntityCoBorrower = response?.result?.entity[1]; // Second entity as co-borrower if available
                  }
              } 
          }
          

            if(response?.result?.broker){
                matchedBroker = response?.result?.broker[0];
            }
            
         
            
            // Check if a matching applicant was found and usertype is BORROWER
            if (matchedApplicant && userTypeApi === "BORROWER") {
                console.log("warm app email response --->", matchedApplicant);
                //console.log("warm app addres response --->", matchedApplicant?.borrowerfulladdress);
                //setSelectedPlace(matchedApplicant?.borrowerfulladdress);
               
                if(!matchedEntityBorrower){
                  setShowInput(false);
                }

                setBorrowerInfoOneData((prevData) => {
                    // Create a copy of the previous data
                    let updatedData = { ...prevData };

                    let selectedAddress ={
                      'borroweraddress' : matchedApplicant?.borroweraddress?.trim() || matchedApplicant?.borrowerfullAddress,
                      'borrowerstate' : matchedApplicant?.borrowerstate,
                      'borrowerzipcode' : matchedApplicant?.borrowerzipcode,
                      'borrowergeolocation' : matchedApplicant?.borrowergeolocation,
                      'borrowergooglemaplink' : matchedApplicant?.borrowergooglemaplink,
                      'borrowerarea': matchedApplicant?.borrowerarea,
                      'borrowercity': matchedApplicant?.borrowercity,
                      'borrowerfulladdress': matchedApplicant?.borrowerfulladdress,
                      'borrowerstateshort': matchedApplicant?.borrowerstateshort,
                      'borrowercounty': matchedApplicant?.borrowercounty,
                    }
                    
                    // Update the copied data with the new key-value pair
                    updatedData = {
                        ...updatedData,
                        ...selectedAddress,
                        
                        "borrowerfirstname": matchedApplicant?.borrowerfirstname || "",
                        "borrowerlastname": matchedApplicant?.borrowerlastname || "",
                        "borrowerphone": matchedApplicant?.borrowerphone || "",
                        'accountexecutive': matchedApplicant?.accountexecutive,
                        'accountexecutiveid': matchedApplicant?.accountexecutiveid || '',
                        "borroweroccupation": matchedApplicant?.borroweroccupation || '',
                        "investmentproperties": matchedApplicant?.investmentproperties || '',
                        "creditscore": matchedApplicant?.statedcreditscore || '',
                        "liquidcashreserve": matchedApplicant?.liquidcashreserve || '',
                        "isborroweranentity": (matchedEntityBorrower) ? 'Yes' : 'No',
                        "entityname": matchedEntityBorrower?.legalname || "",
                        //"borrowerfulladdress": matchedApplicant?.borrowerfulladdress
                        // You can also add additional properties if needed
                    };
                    
                    return updatedData;
                });

               
            }
            // else  if (matchedApplicant && userTypeApi === "COBORROWER") {
            //   console.log("warm app co borrower email response --->", matchedApplicant);

            //   setCoBorrowerSelectedPlace(matchedApplicant?.borrowerfulladdress)
              
            //   setCoBorrowerData(prev => ({ ...prev, 
            //       ["coborrowerfirstname"] : matchedApplicant?.borrowerfirstname,
            //       ["coborrowerlastname"] : matchedApplicant?.borrowerlastname,
            //       ["coborrowerphone"] : matchedApplicant?.borrowerphone,
            //       ["coborroweroccupation"] : matchedApplicant?.borroweroccupation,
            //       ["coborroweinvestmentproperties"] : matchedApplicant?.investmentproperties,
            //       ["coborrowercreditscore"] : matchedApplicant?.statedcreditscore,
            //       ["coborrowerliquidcashreserve"] : matchedApplicant?.liquidcashreserve,
            //       ["iscoborroweranentity"] : matchedApplicant?.isborroweranentity,
            //       ["coborrowerentityname"] : matchedEntityCoBorrower?.legalname,
                  
            //     }));

               
            //   setLoanInfo(prev => ({
            //       ...prev,
            //       coBorrower: {
            //           ...prev.coBorrower,
            //           ["coborrowerfirstname"] : matchedApplicant?.borrowerfirstname,
            //           ["coborrowerlastname"] : matchedApplicant?.borrowerlastname,
            //           ["coborrowerphone"] : matchedApplicant?.borrowerphone,
            //           ["coborroweroccupation"] : matchedApplicant?.borroweroccupation,
            //           ["coborroweinvestmentproperties"] : matchedApplicant?.investmentproperties,
            //           ["coborrowercreditscore"] : matchedApplicant?.statedcreditscore,
            //           ["coborrowerliquidcashreserve"] : matchedApplicant?.liquidcashreserve,
            //           ["iscoborroweranentity"] : matchedApplicant?.isborroweranentity,
            //           ["coborrowerentityname"] : matchedEntityCoBorrower?.legalname,
                      
            //       }
            //   }))
            // }
            else if (matchedApplicant && userTypeApi === "COBORROWER") {
              console.log("warm app co borrower email response --->", matchedApplicant);
          
              // let coBorrowerFieldsData = {
              //   "coborrowerfirstname" : matchedApplicant?.borrowerfirstname,
              //   "coborrowerlastname" : matchedApplicant?.borrowerlastname,
              //   "coborrowerphone" : matchedApplicant?.borrowerphone,
              //   "coborroweroccupation" : matchedApplicant?.borroweroccupation,
              //   "coborroweinvestmentproperties" : matchedApplicant?.investmentproperties,
              //   "coborrowercreditscore" : matchedApplicant?.statedcreditscore,
              //   "coborrowerliquidcashreserve" : matchedApplicant?.liquidcashreserve,
              //   "iscoborroweranentity" : matchedApplicant?.isborroweranentity,
              //   "coborrowerentityname" : matchedEntityCoBorrower?.legalname
              // }
          
              // Set location search for co-borrower
              //setCoBorrowerSelectedPlace(coBorrowerSelectedAddress);
          
              // Set co-borrower data
              // setCoBorrowerData(prev => ({ 
              //     ...prev, 
              //     ...coBorrowerSelectedAddress,  // Include location data
              //     "coborrowerfirstname" : matchedApplicant?.borrowerfirstname,
              //     "coborrowerlastname" : matchedApplicant?.borrowerlastname,
              //     "coborrowerphone" : matchedApplicant?.borrowerphone,
              //     "coborroweroccupation" : matchedApplicant?.borroweroccupation,
              //     "coborroweinvestmentproperties" : matchedApplicant?.investmentproperties,
              //     "coborrowercreditscore" : matchedApplicant?.statedcreditscore,
              //     "coborrowerliquidcashreserve" : matchedApplicant?.liquidcashreserve,
              //     "iscoborroweranentity" : matchedApplicant?.isborroweranentity,
              //     "coborrowerentityname" : matchedEntityCoBorrower?.legalname
              //}));

              setCoBorrowerData((prevData) => {
                  // Create a copy of the previous data
                  let updatedData = { ...prevData }
                  
                  let coBorrowerSelectedAddress = {
                    'coborroweraddress' : matchedApplicant?.borroweraddress?.trim() || matchedApplicant?.borrowerfullAddress,
                    'coborrowerstate' : matchedApplicant?.borrowerstate,
                    'coborrowerzipcode' : matchedApplicant?.borrowerzipcode,
                    'coborrowergeolocation' : matchedApplicant?.borrowergeolocation,
                    'coborrowergooglemaplink' : matchedApplicant?.borrowergooglemaplink,
                    'coborrowerarea': matchedApplicant?.borrowerarea,
                    'coborrowercity': matchedApplicant?.borrowercity,
                    'coborrowerfulladdress': matchedApplicant?.borrowerfulladdress,
                    'coborrowerstateshort': matchedApplicant?.borrowerstateshort,
                    'coborrowercounty': matchedApplicant?.borrowercounty,
                  };
                  updatedData = {
                    ...updatedData,
                    ...coBorrowerSelectedAddress,
                    "coborrowerfirstname" : matchedApplicant?.borrowerfirstname,
                    "coborrowerlastname" : matchedApplicant?.borrowerlastname,
                    "coborrowerphone" : matchedApplicant?.borrowerphone,
                    "coborroweroccupation" : matchedApplicant?.borroweroccupation,
                    "coborroweinvestmentproperties" : matchedApplicant?.investmentproperties,
                    "coborrowercreditscore" : matchedApplicant?.statedcreditscore,
                    "coborrowerliquidcashreserve" : matchedApplicant?.liquidcashreserve,
                    "iscoborroweranentity" : (matchedEntityCoBorrower) ? 'Yes' : 'No',
                    "coborrowerentityname" : matchedEntityCoBorrower?.legalname || ""
                  }
                  return updatedData;

              });
          
              // setLoanInfo(prev => ({
              //     ...prev,
              //     coBorrower: {
              //         ...prev.coBorrower,
              //         ...coBorrowerSelectedAddress,  // Include location data
              //         ...coBorrowerFieldsData
              //     }
              // }));
            }
          
            else if (matchedBroker && userTypeApi === "BROKER") {
              console.log("warm app email response --->", matchedApplicant);
              
              //setSelectedPlace(matchedApplicant?.borrowerfulladdress);
              
              setBorrowerInfoOneData((prevData) => {
                  // Create a copy of the previous data
                  let updatedData = { ...prevData };
                  
                  // Update the copied data with the new key-value pair
                  updatedData = {
                      ...updatedData,
                      "brokerfirstname": matchedBroker?.brokerfirstname || "",
                      "brokerlastname": matchedBroker?.brokerlastname || "",
                      "brokerphonenumber": matchedBroker?.brokerphonenumber || "",
                      "brokeremail": matchedBroker?.brokeremail || "",
                      "brokerpoints": matchedBroker?.brokerpoints || "",
                      // 'accountexecutive': matchedApplicant?.accountexecutive,
                      // 'accountexecutiveid': matchedApplicant?.accountexecutiveid || '',
                      // "borroweroccupation": matchedApplicant?.borroweroccupation || '',
                      // "investmentproperties": matchedApplicant?.investmentproperties || '',
                      // "creditscore": matchedApplicant?.statedcreditscore || '',
                      // "liquidcashreserve": matchedApplicant?.liquidcashreserve || '',
                      // "isborroweranentity": (matchedEntityBorrower) ? 'Yes' : 'No',
                      // "entityname": matchedEntityBorrower?.legalname || ""
                      // You can also add additional properties if needed
                  };
                  
                  return updatedData;
              });
          }
            // Optionally handle the case for CO-BORROWER here
        }
    }).catch((err) => {
        console.error("warm app email response error", err);
    });
  }



  //calling APi when either borower or broker or coborrower email is entered
  useEffect(() => {
    if (sessionUserId) {
        // Only set the payload if there's a valid email present
        if (userTypeApi === "BORROWER" && isValidEmail(borrowerInfoOneData?.borroweremail)) {
            payload.usertype = "BORROWER";
            payload.emailid = borrowerInfoOneData?.borroweremail;
        } else if (userTypeApi === "COBORROWER" && isValidEmail(coBorrowerData?.coborroweremail)) {
            payload.usertype = "COBORROWER";
            payload.emailid = coBorrowerData?.coborroweremail;
        } else if (userTypeApi === "BROKER" && isValidEmail(borrowerInfoOneData?.brokeremail)) {
          payload.usertype = "BROKER";
          payload.emailid = borrowerInfoOneData?.brokeremail;
      }else {
            return; // Exit if there's no valid email
        }

        getEmailDetails(payload); // make the API call with the valid payload
    }
  }, [borrowerInfoOneData?.borroweremail, coBorrowerData?.coborroweremail,borrowerInfoOneData?.brokeremail]);


  useEffect(() => {
      //get the logged in details 
      const getLoggedInData = localStorage.getItem('userdata');
      const parsedLoggedInData = JSON.parse(getLoggedInData);
      console.log('parsed LoggedIn Data',parsedLoggedInData);
      if(parsedLoggedInData){
        setSessionUserId(parsedLoggedInData?.userId);
      }
  },[])

  useEffect(() => {
    // if(Object.keys(borrowerInfoOneData)?.length !== 0){
    //   validateInputs();//call this when existing borrower email id is given to remove existing errors
    // }
    //Get the latest key
    if(Object.keys(borrowerInfoOneData)?.length !== 0){
       const entries = Object.entries(borrowerInfoOneData);
        // Iterate over key-value pairs
        entries.forEach(([key, value]) => {
          if(key !== "borrowerfulladdress" && value){
            removeErrorClass(key);
          }else if(key === "borrowerfulladdress" && value){
            const searchWrpElement = document.querySelector(".search_wrp");
            if (searchWrpElement) {
                    searchWrpElement.classList.remove("invalid-input");
            }
          }
        });
    }
    
  },[borrowerInfoOneData])

  useEffect(() => {
    // if(Object.keys(borrowerInfoOneData)?.length !== 0){
    //   validateInputs();//call this when existing borrower email id is given to remove existing errors
    // }
    //Get the latest key
    if(coBorrowerData?.coBorrowerExists === "yes" && Object.keys(coBorrowerData)?.length !== 0){
       const entries = Object.entries(coBorrowerData);
        // Iterate over key-value pairs
        entries.forEach(([key, value]) => {
          if(key !== "coborroweraddress" && value){
            removeErrorClass(key);
          }
          // else if(key === "coborroweraddress" && value){
          //   const searchWrpElement = document.querySelector(".search_wrp");
          //   if (searchWrpElement) {
          //           searchWrpElement.classList.remove("invalid-input");
          //   }
          // }
        });
    }
    
  },[coBorrowerData])

    
    return (
        <>
            <div className="create_process">
                <Header/>
                <div className="content_wrp">
                   <InfoBar currentStage={'borrowerinfo'} progress={'100%'} infoProgress={'100%'} dealProgress={'100%'} strategyProgress={'100%'} borrowerProgress={setWidth} />
                        {currentPage == 1 && 
                        <div className="content_blk">
                            <div className="hed">
                                {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                                <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); navigate('/StrategyInfo');}}> keyboard_backspace </a>
                                                         

                                <h2 className="title"> <span className="title2">{loanInfo?.propertyinfo
?.programtype?.split(' ')[0]} <span  className="sub">{loanInfo?.propertyinfo
    ?.programtype?.split(' ').slice(1).join(' ')}</span></span> Borrower Info
                        </h2>
                            </div>
                            <div className="cont">
                                <h3 className="feild_title">Now we want to know more about you!</h3>
                                
                                <div className="form">
                                    <div className="row">
                                    {formJSON.map((element, index) => (
                                      element.type === 'buttonGroup' ? (
                                        <div className="col-md-6" key={index}>
                                        <div className="form-group align-center">
                                            <label>{element?.label}</label><div className='button_group'>
                                            {element.options.map((option) => (
                                          
                                          <button
                                             key={option.value}
                                             type="button"
                                             className="cp_btn"
                                             style={
                                              borrowerInfoOneData[element.name] === option.value
                                                 ? { background: 'rgba(149, 201, 113, 0.20)' }
                                                 : {}
                                             }
                                             name={element.name}
                                             onClick={() => handleButtonChange(element.name, option.value)}
                                             id={option.id}
                                             onFocus={() => removeErrorClass(element.name)}
                                           >
                                             {option.label}
                                           </button>
                                          
                                         ))}</div> </div>
                                        </div>
                                        
                                     ) : element.type === 'address' ? 
                                          <div className="col-md-12" key={index}> 
                                            <div className="search_block">
                                            <h3 className="feild_title">{element.label}</h3>
                                              <div className="search_wrp">
                                                <span className="material-icons-outlined">
                                                  location_on
                                                </span>
                                                {mapsKey &&  
                                                 <LocationSearch name={element.name} setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace={borrowerInfoOneData?.borroweraddress || selectedPlace} simpleAddress = {true} />
                                                }
                                               
                                                {/* <button className="search_btn"> */}
                                                  <span className="material-symbols-outlined">
                                                    search
                                                  </span>
                                                {/* </button> */}
                                              </div>
                                            </div></div> : element.type === 'dropdown' ?
                                            <div className="col-md-6" key={index}>  <div className="form-group"> <label>{element?.label}</label><div className="select_box">
                                                {/* <select name="" className="form-control" onChange={(e) => navigate('/BorrowerInfo5')} > */}
                                                <select id={element.id} name={element.name} className="form-control" onChange={handleInputChange} value={borrowerInfoOneData[element?.name]}  onFocus={() => removeErrorClass(element.name)}>
                                                 {element.name == 'accountexecutive' ? usersOptions.map((option) => ( <option value={option.value}>{option.label}</option>)) : element.name == 'referredBy' ?  referralSource.map((option) => ( <option value={option.value}>{option.label}</option>))
                                                    :  element.options.map((option) => ( <option value={option.value}>{option.label}</option>))}
                                                </select></div></div></div>:
                                        element.name === "entityname" && showInput === false ? ""
                                        : <div className="col-md-6" key={index}>
                                            <div className="form-group">
                                                <label>{element?.label}</label>
                                                {floatFields.includes(element?.name) ?
                                                    <CurrencyInput
                                                      name={element.name}
                                                      //   defaultValue={1000}
                                                      placeholder="$0.00"
                                                      decimalsLimit={2}
                                                      className="form-control"
                                                      prefix="$"
                                                      groupSeparator=","
                                                      value={borrowerInfoOneData[element?.name]}
                                                      onValueChange={(value, name, values) => {
                                                        //console.log(values)
                                                        handleFloatInputs(name,value);
                                                      }}
                                                      id={element.id}
                                                      onFocus={() => removeErrorClass(element.name)}
                                                    /> : 
                                                    <input type={element?.type} className="form-control" name={element?.name} value={borrowerInfoOneData[element?.name]} 
                                                      placeholder={element?.name === 'brokerphonenumber' || element?.name === 'borrowerphone' ? element?.placeholder : ''} 
                                                      pattern={element?.name === 'brokerphonenumber' || element?.name === 'borrowerphone' ? element?.pattern : ''}
                                                      onChange={(e) => {
                                                        
                                                        if (element?.name === 'brokerphonenumber' || element?.name === 'borrowerphone') {
                                                            e.target.value = formatPhoneNumber(e.target.value);
                                                            handleInputChange(e);
                                                        }
                                                        handleInputChange(e);
                                                      }}
                                                      id={element.id}
                                                      onFocus={handleFocus}
                                                    />
                                                    
                                                }
                                                {errorMsg && errorMsg[element?.name] && (
                                                    <span className='error_msg' style={{ color: 'red' }}>{errorMsg[element?.name]}</span>
                                                )}
                                            </div>
                                          </div>
                                    ))}
                                       
                                        {/* <div className="col-md-12 text-center">
                                            <button type="button" className="cp_btn mt-10" onClick={(e) => {navigate('/BorrowerInfo2');}}>Next</button>
                                            <button id="borrowerBrokerNext" type="button" className="cp_btn mt-10" onClick={submitBorrowerInfo}>Next</button>
                                        </div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                        }

                            <div className="content_blk contentblk_new">
                                <div className="cont">
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                {(loanInfo?.coborrowerflag === false) && <button className="material-icons-outlined expand_icon" onClick={() => handleExpandChange('up')} style={{ border: 'none', outline: 'none', backgroundColor: 'transparent' }}>expand_more</button>}
                                                {(loanInfo?.coborrowerflag === true)&& <button className="material-icons-outlined expand_icon" onClick={() => handleExpandChange('down')} style={{ border: 'none', outline: 'none', backgroundColor: 'transparent' }}>expand_less</button>}
                                                <div className="align-center">
                                                    <label>Is there any co-borrower for the loan?</label>
                                                    <div className="button_group">
                                                        <button type="button" className={`cp_btn`} name="coBorrowerYes" 
                                                          id="coBorrowerYes" onClick={handleCoBorrowerLoan} 
                                                          style={
                                                            loanInfo?.coborrowerflag === true
                                                               ? { background: 'rgba(149, 201, 113, 0.20)' }
                                                               : {}
                                                           }>Yes</button>
                                                        <button type="button" className={`cp_btn`} name="coBorrowerNo" id="coBorrowerNo" 
                                                          onClick={handleCoBorrowerLoan}
                                                          style={
                                                            loanInfo?.coborrowerflag === false
                                                               ? { background: 'rgba(149, 201, 113, 0.20)' }
                                                               : {}
                                                           }
                                                          >No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {coBorrowerData['coBorrowerExists'] && coBorrowerData['coBorrowerExists'] == 'yes' && <div className="row coborrower_details mt-4">
                                            <h3 className="feild_title mb-4">Okay, Tell Us about the Co-Borrower</h3>
                                                {coBorrowerJson?.map((coBorrower,index) => {
                                                  if(coBorrower?.type === 'text' || coBorrower?.type === 'number'){
                                                      return(
                                                        <div className="col-md-6" key={index}>
                                                          <div className="form-group">
                                                              
                                                              { (coBorrower?.name !== 'coborrowerentityname') ?
                                                                  <>
                                                                      <label>{coBorrower?.label}</label>
                                                                      {coBorrower?.name !== 'coborrowerliquidcashreserve' && 
                                                                        <input type={coBorrower?.type} 
                                                                            className={`form-control`}
                                                                            name={coBorrower?.name} placeholder={coBorrower?.placeholder} 
                                                                            pattern={coBorrower?.name === 'coborrowerphone' ? coBorrower?.pattern : ''}
                                                                            id={coBorrower?.id} 
                                                                            value={coBorrowerData[coBorrower?.name]}
                                                                            onFocus={handleCoBorrowerFocus} 
                                                                            onChange={(e) => {
                                                                              if(coBorrower?.name === 'coborrowerphone'){
                                                                                  e.target.value = formatPhoneNumber(e.target.value);
                                                                                  handleCoBorrowerChange(e);
                                                                              }
                                                                              handleCoBorrowerChange(e);
                                                                            }}
                                                                            
                                                                        />
                                                                      }
                                                                      {coBorrower?.name == 'coborrowerliquidcashreserve' && 
                                                                            <CurrencyInput
                                                                              name={coBorrower?.name}
                                                                              //   defaultValue={1000}
                                                                              placeholder="$0.00"
                                                                              decimalsLimit={2}
                                                                              className='form-control'
                                                                              //className={`form-control ${coBorrowerErrors[coBorrower?.name] && 'invalid-input'}`}
                                                                              prefix="$"
                                                                              groupSeparator=","
                                                                              value={coBorrowerData[coBorrower?.name]}
                                                                              onValueChange={(value, name, values) => {
                                                                                //console.log(values)
                                                                                handleCoBorrowerFloatInputs(name,value);
                                                                              }}
                                                                              id={coBorrower?.id}
                                                                              //onFocus={removeErrorClass(coBorrower?.name)}
                                                                              //onFocus={() => removeErrorClass(element.name)}
                                                                            />
                                                                      }
                                                                  </>
                                                                    
                                                                  :
                                                                (coBorrower?.name == 'coborrowerentityname'  && coBorrowerData['iscoborroweranentity'] == 'Yes') ? 
                                                                    <>
                                                                        <label>{coBorrower?.label}</label> 
                                                                        <input type={coBorrower?.type}  className={`form-control`} name={coBorrower?.name} placeholder={coBorrower?.placeholder} pattern="" id={coBorrower?.id} 
                                                                          value={coBorrowerData[coBorrower?.name]} onChange={handleCoBorrowerChange} onFocus={handleCoBorrowerFocus}  />
                                                                    </>
                                                                    
                                                                : ''
                                                              }
                                                          </div>
                                                        </div>
                                                      )
                                                  }else if(coBorrower?.type === 'dropdown'){
                                                      return(
                                                        <div className="col-md-6" key={index}>
                                                          <div className="form-group">
                                                              <label>{coBorrower?.label}</label>
                                                              <div className="select_box">
                                                                <select
                                                                    className={`form-control ${coBorrowerErrors[coBorrower?.name] && 'invalid-input'}`}
                                                                    name={coBorrower?.name}
                                                                    id={coBorrower?.id}
                                                                    onChange={handleCoBorrowerChange}
                                                                    value={coBorrowerData[coBorrower?.name]}
                                                                    // onFocus={removeErrorClass(coBorrower?.name)}
                                                                >
                                                                    {coBorrower.options.map((option, idx) => (
                                                                        <option key={idx} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                              </div>
                                                          </div>
                                                        </div>
                                                      )
                                                  } else if(coBorrower?.type === 'buttonGroup') {
                                                      return(
                                                        <div className="col-md-6" key={index}>
                                                          <div className="form-group align-center">
                                                              <label>{coBorrower?.label}</label>
                                                              <div className='button_group'>
                                                                {coBorrower?.options?.map((buttonOption, buttonidx) => (
                                                                    <button key={buttonidx} type="button"  className={`cp_btn`} name={buttonOption.id} 
                                                                      id={buttonOption.id} 
                                                                      style={
                                                                        coBorrowerData[coBorrower?.name] === buttonOption.value
                                                                           ? { background: 'rgba(149, 201, 113, 0.20)' }
                                                                           : {}
                                                                       }
                                                                      onClick={handleCoBorrowerEntity}>{buttonOption.label}</button>
                                                                ))}
                                                              </div>
                                                          </div>
                                                        </div>
                                                      )
                                                  }else if(coBorrower?.type === 'address') {
                                                    return(
                                                        <div className="col-md-12" key={index}> 
                                                          <div className="search_block">
                                                              <h3 className="feild_title">{coBorrower.label}</h3>
                                                              <div className="search_wrp">
                                                                <span className="material-icons-outlined">
                                                                  location_on
                                                                </span>
                                                                {mapsKey && 
                                                                <LocationSearch name={coBorrower.name} setCoBorrowerAddressData={setCoBorrowerAddressData} 
                                                                coBorrowerAddressData={coBorrowerAddressData} 
                                                                setCoBorrowerSelectedPlace={setCoBorrowerSelectedPlace} 
                                                                coBorrowerSelectedPlace={coBorrowerData.coborrowerfulladdress || coBorrowerSelectedPlace} 
                                                                simpleAddress = {true}
                                                            />
                                                                }
                                                                
                                                                {/* <button className="search_btn"> */}
                                                                  <span className="material-symbols-outlined">
                                                                    search
                                                                  </span>
                                                                {/* </button> */}
                                                              </div>
                                                          </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return null;
                                                  }
                                                    
                                                })}
                                               
                                        </div>}
                                    </div>
                                </div>
                           </div>
                           <div className="content_blk contentblk_new">
                                <div className="cont">
                                    <div className="form">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                              <button id="borrowerBrokerNext" type="button" className="cp_btn" onClick={submitBorrowerInfo}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           </div>



                        {
                        currentPage !== 1 && getBorrowerPage(currentPage)
                        }
                </div>   
                <div className="cp_footer">
                    <div className="wrapper">
                        <p>{configdata?.admin?.rights}</p>
                        <ul>
                            <li><a href="privacy">Privacy </a></li>
                            <li><a href=""> | </a></li>
                            <li><a href="terms">Terms of Use</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
        
        
        
        
    );
};

export default BorrowerInfo;