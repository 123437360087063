import React , { useState ,useEffect } from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { getAllFundManagmentAdmin , getAllInvestors, investorStatements , defaultBulkInvestorTemplate ,
    bulkInvestorStatements , statementsLogs
} from '../utils/reducer';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from "xlsx";
import EditorEdit from './EditorEdit.js';
import EditorEditNew from './EditorEditNew.js';

const AdminInvestorStatements = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('');
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [investorFundDropdown,setInvestorFundDropdown] = useState([]);
    const [singleInvestorData,setSingleInvestorData] = useState({});
    const [bulkInvestorData,setBulkInvestorData] = useState({});
    const [singleInvestorErrors,setSingleInvestorErrors] = useState({});
    const [bulkInvestorErrors,setBulkInvestorErrors] = useState({});
    const [content, setContent] = useState('');
    const [emailBody, setEmailbody] = useState('');
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [fundId,setFundId] = useState(null);//uuid
    const [investorId,setInvestorId] = useState(null);
    const [hidePrint,setHidePrint] = useState('no');
    const [statementsLogData,setStatementsLogData] = useState([]);
    const [sortDirection,setSortDirection] = useState('asc');
    
    let singleErrors = {};
    let bulkErrors = {};

    const investorOptions = [
        { value: "1504", label: "Easy Street Admin" },
        { value: "1510", label: "Stephen Hagerman" },
        { value: "1511", label: "Ashton Matthias" },
        { value: "1512", label: "Steven and Brooke Malfatto" },
        { value: "1517", label: "Kingdom Trust FBO Michael Denton Traditional IRA" },
        { value: "1518", label: "Richard Hagerman" },
        { value: "1519", label: "Erik and Tasha Wahl" }
    ];

    const bulkInvestorSample = [
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        // Add more entries as needed
    ];

    const columnWidth = '20%'; // Adjust the width for each column as needed

    const columns = [
        {
            name: '#',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            minWidth: '10%',
            maxWidth: '10%',
            sortable: false,
        },
        {
            name: 'Investor Name',
            selector: (row) => row?.investorName,
            sortable: true,
            minWidth: '20%',
            maxWidth: '20%',
            sortFunction: (a, b) => {
    
                const nameA = a?.investorName?.toLowerCase();
                const nameB = b?.investorName?.toLowerCase();
          
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; 
            },
        },
        {
            name: 'Email ID',
            selector: (row) => row?.toEmailid,
            sortable: true,
            minWidth: '25%',
            maxWidth: '25%',
            sortFunction: (a, b) => {
    
                const nameA = a?.toEmailid?.toLowerCase();
                const nameB = b?.toEmailid?.toLowerCase();
          
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; 
            },
        },
        {
            name: 'Statement Type',
            selector: (row) => (row?.statementType == "bulk") ? 'Bulk Investor' : 'Single Investor',
            sortable: true,
            minWidth: '15%',
            maxWidth: '15%',
            sortFunction: (a, b) => {
    
                const nameA = a?.statementType?.toLowerCase();
                const nameB = b?.statementType?.toLowerCase();
          
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; 
            },
        },
        {
            name: 'Pulled Date',
            selector: (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '',
            sortable: true,
            minWidth: '15%',
            maxWidth: '15%',
            sortFunction: (a, b) => {
    
                const nameA = a?.investorName?.toLowerCase();
                const nameB = b?.investorName?.toLowerCase();
          
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0; 
            },
        },
        {
            name: 'Pulled By',
            selector: (row) => row?.userName,
            sortable: true,
            minWidth: '15%',
            maxWidth: '15%',
        },
    ];
    
    useEffect(() => {
        getFundsName()
    }, []);

    const getFundsName =()=>{
        dispatch(showLoader());
        let type = 'fundDropdown'
    getAllFundManagmentAdmin('','',type)
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result) {
                setFundsDropdown(response?.result?.data);
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }

    useEffect(() => {
        if(fundsDropdown?.length > 0){
            setSingleInvestorData({
                ...singleInvestorData,
                ["name"] : "Easy Street Capital LLC"
            });
            // setBulkInvestorData({
            //     ...bulkInvestorData,
            //     ["name"] : "Easy Street Capital LLC"
            //});
            const selectedFund = fundsDropdown.find((fund) => fund.name == "Easy Street Capital LLC");
            if (selectedFund) {
                setFundId(selectedFund.uuid); // Update fundId with uuid
            }
        }
    },[fundsDropdown])

    // const handleTab = (param) => {
    //     //dispatch(showLoader());
    //     if(param === "singleinvestor") {
    //         console.log("Switching to Single Investor tab");
    //         setSingleInvestorErrors({});
    //         // Resetting single investor data by setting to a new empty object
    //         setSingleInvestorData({
            
    //             name: 'Easy Street Capital LLC',
    //             startdate: '',
    //             enddate: '',
    //             investorFund: ''
    //         });
    //         setContent("");
    //         setFundId('');
    //         setInvestorId('');
    //         if(fundsDropdown?.length > 0){
    //             setSingleInvestorData({
    //                 ...singleInvestorData,
    //                 ["name"] : "Easy Street Capital LLC"
    //             });
                
    //             const selectedFund = fundsDropdown.find((fund) => fund.name == "Easy Street Capital LLC");
    //             if (selectedFund) {
    //                 setFundId(selectedFund.uuid); // Update fundId with uuid
    //             }
    //         }
            
    //     } else if(param === "bulkinvestor") {
    //         getDefaultBulkTemplate();
    //         console.log("Switching to Bulk Investor tab");
    //         setBulkInvestorErrors({});
    //         // Resetting bulk investor data by setting to a new empty object
    //         setBulkInvestorData({
    //             name: 'Easy Street Capital LLC',
    //             pullstartdate: '',
    //             pullenddate: ''
    //         });
    //         setFundId('');
    //         setInvestorId('');
    //         if(fundsDropdown?.length > 0){
                
    //             setBulkInvestorData({
    //                 ...bulkInvestorData,
    //                 ["name"] : "Easy Street Capital LLC"
    //             });
    //             const selectedFund = fundsDropdown.find((fund) => fund.name == "Easy Street Capital LLC");
    //             if (selectedFund) {
    //                 setFundId(selectedFund.uuid); // Update fundId with uuid
    //             }
    //         }
    //         getDefaultBulkTemplate();

            
    //         //setContent("");
    //     }
    //     //dispatch(hideLoader());
    // }

    const handleTab = (param) => {
        if (param === "singleinvestor") {
            console.log("Switching to Single Investor tab");
            setSingleInvestorErrors({});
            
            const initialSingleInvestorData = {
                name: 'Easy Street Capital LLC',
                startdate: '',
                enddate: '',
                investorFund: ''
            };
            
            setSingleInvestorData(initialSingleInvestorData); // Set initial state
    
            setContent("");  // Reset content
            setEmailbody("");
            // Assuming you might want to set FundId based on some criteria
            setFundId('');   // Reset fundId
            setInvestorId(''); // Reset investorId
    
            // If you need to set the fundId based on the initial data
            const selectedFund = fundsDropdown.find((fund) => fund.name === initialSingleInvestorData.name);
            if (selectedFund) {
                setFundId(selectedFund.uuid);
            }
            
        } else if (param === "bulkinvestor") {
            console.log("Switching to Bulk Investor tab");
            setBulkInvestorErrors({});
            
            const initialBulkInvestorData = {
                name: 'Easy Street Capital LLC',
                pullstartdate: '',
                pullenddate: ''
            };
            
            setBulkInvestorData(initialBulkInvestorData); // Set initial state for bulk
            setEmailbody("");
            //setFundId(''); // Reset fundId
            setInvestorId(''); // Reset investorId
            const selectedFund = fundsDropdown.find((fund) => fund.name === initialBulkInvestorData.name);
            if (selectedFund) {
                setFundId(selectedFund.uuid);
            }
            getDefaultBulkTemplate(); // Fetch the default template
        }else if (param === "statementslog") {
            callStatementsLog();
        }
    }

    //call API and set value in investor fund dropdown
    const getInvestorFunds = () => {
        dispatch(showLoader());
        getAllInvestors(0,0,singleInvestorData?.name)
            .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data?.length > 0) {
                setInvestorFundDropdown(response?.result?.data);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error("error", err); 
                dispatch(hideLoader());
        });
       
       
    }

    useEffect(() => {
        if(singleInvestorData?.name !== ""){
            console.log("calling get investor funds api");
            setInvestorFundDropdown([]);
            getInvestorFunds();
        }else{
            setInvestorFundDropdown([]);
        }
    },[singleInvestorData?.name])

    const handleChange = (e,param) => {
        let name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;

        // Check if the input type is "date" and format it
        //if (type === "date") {
            // const date = new Date(value);
            // value = date.toLocaleDateString("en-US", {
            //     year: 'numeric',
            //     month: '2-digit',
            //     day: '2-digit'
            // }).replace(/\//g, "-"); // Format to MM-dd-yyyy
            
            // // Store in ISO format (yyyy-MM-dd) for compatibility with input type="date"
            // value = date.toISOString().split('T')[0];
            //value = moment.utc(value).format("MM-DD-YYYY");
        //}


        if(param == "singleinvestor"){
            setSingleInvestorData((prevData) => ({
                ...prevData,
                [name] : value
            }));
           
        }else{
            setBulkInvestorData((prevData) => ({
                ...prevData,
                [name] : value
            }));
            
        }

        // Set fundId based on selected fund name
        if (name === "name") {
            const selectedFund = fundsDropdown.find((fund) => fund.name === value);
            if (selectedFund) {
                setFundId(selectedFund.uuid); // Update fundId with uuid
            } else {
                setFundId(null); // Reset if no match is found
            }
        }else if(name === "investorFund"){
            const selectedFund = investorFundDropdown.find(
                (fund) => `${fund.firstname} ${fund.lastname}` === value
            );
            if (selectedFund) {
                setInvestorId(selectedFund.investorid); // Update fundId with uuid
            } else {
                setInvestorId(null); // Reset if no match is found
            }
        }
        
    }

    const handleFocus = (e,param) => {
        let name = e.target.name;
       

        if(param === "singleinvestor"){
            setSingleInvestorErrors((prevErrors) => ({
                ...prevErrors,
                [name] : null
            }));
        }else if(param === "bulkinvestor"){
            setBulkInvestorErrors((prevErrors) => ({
                ...prevErrors,
                [name] : null
            }));
        }

        
    }

    const handleValidation = (param) => {
        singleErrors = {};
        const singleInvestorFields = ["name","startdate","enddate","investorFund"];
        bulkErrors = {};
        const bulkInvestorFields = ["name","pullstartdate","pullenddate"];
        
        if(param === "singleinvestor"){
            singleInvestorFields?.forEach((field) => {
                if(!singleInvestorData[field]){
                    singleErrors[field] = "Required";
                }
            })
            setSingleInvestorErrors(singleErrors);
            return Object.keys(singleErrors)?.length === 0;
        }else if(param === "bulkinvestor"){
            bulkInvestorFields?.forEach((field) => {
                if(!bulkInvestorData[field]){
                    bulkErrors[field] = "Required";
                }
            })
            setBulkInvestorErrors(bulkErrors);
            return Object.keys(bulkErrors)?.length === 0;
        }

        return true;
       
    }

    /* single Investor Start */

    useEffect(() => {
        console.log('quillValuequillValue',singleInvestorData)
        setSingleInvestorData((prevData) => ({
            ...prevData,
            ['template']: content,
        }));
        
    }, [content]);

    useEffect(() => {
        console.log('quillValuequillValue',singleInvestorData)
        setSingleInvestorData((prevData) => ({
            ...prevData,
            ['body']: emailBody,
        }));
        
    }, [emailBody]);

    const handleGenerate = () => {
        const valid = handleValidation("singleinvestor");
        if(valid){
            console.log(singleInvestorData?.startdate,singleInvestorData?.enddate,fundId,investorId);
            dispatch(showLoader());
            investorStatements(singleInvestorData?.startdate,singleInvestorData?.enddate,fundId,investorId)
                .then(function(response) {
                console.log('funds name',response)
                if (response?.result?.template) {
                    //setPopupOpen(true);
                    //setMessage("Generated Succesfully");
                    setContent(response?.result?.template);
                    setEmailbody(response?.result?.bodytemplate);
                    console.log("singleinestor response",response?.result);
                    // setSingleInvestorData({

                    //     name: 'Easy Street Capital LLC',
                    //     startdate: '',
                    //     enddate: '',
                    //     investorFund: ''
                    // });
                }else{
                    setContent(""); // Clear any previous content
                    setPopupOpen(true);
                    setMessage("No data found for the selected criteria.");
                   
                }
                dispatch(hideLoader());
            })
                .catch((err) => {
                    console.error("error", err); 
                    dispatch(hideLoader());
            });
        }
    }

    const sendSingleInvestorEmail = () => {
        //const valid = handleValidation("singleinvestor");
        //if(valid){
            console.log(singleInvestorData?.startdate,singleInvestorData?.enddate,fundId,investorId);
            const updatedData = {
                template : content,
                bodytemplate : emailBody
            };
            dispatch(showLoader());
            investorStatements(singleInvestorData?.startdate,singleInvestorData?.enddate,fundId,investorId,updatedData)
                .then(function(response) {
               
                if (response?.result == "Email Sent Successfully") {
                    setPopupOpen(true);
                    setMessage("Email sent successfully!");
                    console.log("singleinestor response",response?.result);
                    setSingleInvestorData({

                        name: 'Easy Street Capital LLC',
                        startdate: '',
                        enddate: '',
                        investorFund: ''
                    });
                    setContent("");
                    setEmailbody("");
                }else{
                    //setContent(""); // Clear any previous content
                    setPopupOpen(true);
                    setMessage("Failed to send the email statement. Please check your inputs and try again.");
                   
                }
                dispatch(hideLoader());
            })
                .catch((err) => {
                    console.error("error", err); 
                    dispatch(hideLoader());
            });
        //}
    }

    
    /* single Investor End */

    /* bulk investor tab start */

    const handlePull = () => {
        const valid = handleValidation("bulkinvestor");
        if(valid){
            const updatedData = {
                template : content,
                bodytemplate : emailBody
            };
            dispatch(showLoader());
            bulkInvestorStatements(bulkInvestorData?.pullstartdate,bulkInvestorData?.pullenddate,fundId,updatedData)
            .then(function(response) {
                console.log('bulkInvestorStatements',response)
                if (response?.result == "Email Sent Successfully") {
                    setContent('');
                    setEmailbody("");
                    setPopupOpen(true);
                    setMessage("Email sent successfully!");
                    setBulkInvestorData({
                        name: 'Easy Street Capital LLC',
                        pullstartdate: '',
                        pullenddate: '',
                        
                    });
                    
                }
                dispatch(hideLoader());
                getDefaultBulkTemplate();
            })
            .catch((err) => {
                console.error("error", err); 
                dispatch(hideLoader());
            });
        }
    }

    const editSheet = (data) => {
        console.log('data',data)
        //navigate('/createfundmanagement', { state: { fundId : data?.uuid,selectedfund: data, fundManagementData:fundManagementData , mode : "Edit" ,fundNames } });
    };
    
    const deleteSheet = (data) => {
        // setShowConfirmation(true);
        // setSelectedOption(data?.uuid);
        // console.log('data',data);
    };

    useEffect(() => {
        callStatementsLog(); // Fetch the initial statements log on component mount
    }, [currentPage, perPage]); 

    const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
        //callStatementsLog();
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        //callStatementsLog();
    };

    

    const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
    };

    const handleExport = () => {
        const exportData = (bulkInvestorSample?.length > 0) ? bulkInvestorSample : [];

        if(exportData?.length > 0){
            // Prepare the worksheet data from the provided `data` array
            const worksheetData = exportData?.map((item) => ({
                "Pulled Date": item.pulledDate,
                "Pulled By": item.pulledBy,
                "Status": item.status,
                "Upload to Bucket Failed": item.uploadToBucketFailed,
                
            }));
            
            console.log('Exporting data:', worksheetData);
            
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a worksheet with the prepared data
            const ws = XLSX.utils.json_to_sheet(worksheetData);

             // Set column widths to encourage text wrapping in Excel
            ws['!cols'] = [
                { wch: 15 },  // Width for "Pulled Date"
                { wch: 20 },  // Width for "Pulled By"
                { wch: 10 },  // Width for "Status"
                { wch: 10 },  // Width for empty column D
                { wch: 10 },  // Width for empty column E
                { wch: 10 }   // Width for "Upload to Bucket Failed" (column F) to allow wrapping
            ];

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Statements Log");
            
            // Define the filename based on the context
            const filename = "bulk_investors_statement.xlsx";
            // Export the workbook
            XLSX.writeFile(wb, filename);
            
        }

        
    }

    const getDefaultBulkTemplate =()=>{
        dispatch(showLoader());
        defaultBulkInvestorTemplate()
        .then(function(response) {
            console.log('getDefaultBulkTemplate',response)
            if (response?.result?.template) {
                setContent(response?.result?.template);
                setEmailbody(response?.result?.bodytemplate);
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }
    
    

    /* bulk investor tab end */

    /* staements log */

    const callStatementsLog = () => {
       
        dispatch(showLoader());
        statementsLogs(currentPage,perPage)
        .then(function(response) {
            console.log('callStatementsLog',response)
            if (response?.result?.data) {
                setStatementsLogData(response?.result?.data);
                setTotalCount(response?.result?.totalCount);
                //setCurrentPage(response?.result?.PageNumber);
                //setPerPage(response?.result?.assetcount);
            }
            dispatch(hideLoader());
           
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }

    // const handleSort = (field, direction) => {
    //     // Update your API call logic
    //     fetchData({
    //         sortField: field,
    //         sortDirection: direction,
    //         currentPage,
    //         perPage,
    //     });
    // };

    const paginatedData = statementsLogData.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const handleSort = (column, sortDirection) => {
        const sortField = column.selector;
    
        const safeValue = (value) => (value == null ? '' : String(value).trim().toLowerCase()); // Trim and normalize casing
    
        const newSortedData = [...statementsLogData].sort((a, b) => {
            const aValue = typeof sortField === 'function' ? safeValue(sortField(a)) : safeValue(a[sortField]);
            const bValue = typeof sortField === 'function' ? safeValue(sortField(b)) : safeValue(b[sortField]);
    
            if (column.name === 'Investor Name') {
                console.log('Sorting Investor Name:', aValue, bValue); // Debugging
                return sortDirection === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }
    
            // Default sorting logic for other columns
            return sortDirection === 'asc' 
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        });
    
        setStatementsLogData(newSortedData);
    };
    
    
    

    const closePopup = () => {
        setPopupOpen(false);
        //navigate('/fundmanagement');
    };

    return (
        
        <div id="layout-wrapper">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                type={message === "No data found for the selected criteria." ||
                    message === "Failed to send the email statement. Please check your inputs and try again."
                    ? 'warning' : 'success'}
                message={message}
                onClose={closePopup}
                />
            )}

          <MainHeader />
          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Investor Statement</h4>
                      
                            </div>
                            <div className="card">
                            <div className="card-body my_account">
                            
                            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#single" role="tab" onClick={() => handleTab("singleinvestor")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">SINGLE investor statements</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#bulk" role="tab" onClick={() => handleTab("bulkinvestor")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">BULK INVESTOR STATEMENTS</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#statementslog" role="tab" onClick={() => handleTab("statementslog")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">STATEMENTS LOG</span>
                                </a>
                            </li>

                        </ul>


                        <div class="tab-content pt-15 text-muted">
                            <div class="tab-pane active" id="single" role="tabpanel">
                                <div className='search'>
                                    <div className="form-group">
                                        <label>Select a Fund</label>
                                        <select id="fundName" name="name"  tabindex="1" 
                                        className={`form-control ${singleInvestorErrors?.name && 'invalid-input'}`}
                                        onFocus={(e) => handleFocus(e,"singleinvestor")}
                                        onChange={(e) => handleChange(e,"singleinvestor")} value={singleInvestorData?.name}>
                                            <option value="">Select Fund</option>
                                            {fundsDropdown?.map((fundoption,index)=>{
                                                return(
                                                    <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                                )
                                            })}
                                        
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Select a Start date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" 
                                            className={`form-control ${singleInvestorErrors?.startdate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"singleinvestor")}
                                            onChange={(e) => handleChange(e ,"singleinvestor")} value={singleInvestorData?.startdate}
                                            max={singleInvestorData?.enddate || undefined} // Disable dates after the selected end date
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select a End date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="enddate" tabindex="3" 
                                            className={`form-control ${singleInvestorErrors?.enddate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"singleinvestor")}
                                            onChange={(e) => handleChange(e ,"singleinvestor")} value={singleInvestorData?.enddate}
                                            min={singleInvestorData?.startdate || undefined} // Disable dates before the selected start date
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select an Investor</label>
                                        <select id="prefillLoanProgram" name="investorFund" tabindex="4" 
                                        className={`form-control ${singleInvestorErrors?.investorFund && 'invalid-input'}`}
                                        onFocus={(e) => handleFocus(e,"singleinvestor")}
                                        onChange={(e) => handleChange(e,"singleinvestor")} value={singleInvestorData?.investorFund}>
                                            {investorFundDropdown?.length === 0 ? 
                                                <option value="">No Investors Found</option>
                                                : <option value="">Select Investor</option>}
                                            {investorFundDropdown?.map((fundoption,index) => {
                                                return(
                                                    // <option key={index} value={fundoption?.firstname + fundoption?.lastname}>{fundoption?.firstname + fundoption?.lastname}</option>
                                                    <option key={index} value={`${fundoption.firstname} ${fundoption.lastname}`}>
                                                        {fundoption.firstname} {fundoption.lastname}
                                                    </option>
                                                )
                                            })}
                                            
                                        </select>
                                    </div>
                                    <div className="form-group generate">
                                        <label></label>
                                        <button type="button" className="create_btn " onClick={handleGenerate}>Generate <span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div>
                                        
                                </div>
                                <hr className="hr"></hr>
                           
                                <div className="row form_section investor_block">
                                <div className='col-md-6'>
                                <h4 class="title mb_10">MAIL BODY</h4>
                                    <div className="investor-editor-container">
                                        <EditorEditNew emailBody={emailBody} setEmailbody={setEmailbody} name="template" hidePrint={hidePrint} />
                                    </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="flex">
                                    <h4 class="title mb_10">MAIL Statement</h4>
                                    <button id="" type="button" class="border_btn" onClick={sendSingleInvestorEmail}>Send Email<span className="material-symbols-outlined">mail</span></button>
                                    </div>
                                    <div className="investor-editor-container">
                                        <EditorEdit content={content} setContent={setContent} name="template" hidePrint={hidePrint} />
                                    </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane bulk_investor" id="bulk" role="tabpanel">
                                <div className='search'>
                                    <div className="form-group">
                                        <label>Select a Fund</label>
                                        <select id="fundName" name="name"  tabindex="1" 
                                            className={`form-control ${bulkInvestorErrors?.name && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e,"bulkinvestor")} value={bulkInvestorData?.name}>
                                                <option value="">Select Fund</option>
                                                {fundsDropdown?.map((fundoption,index)=>{
                                                    return(
                                                        <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                                    )
                                                })}
                                        
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Statement Pull Start Date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="pullstartdate" tabindex="2" 
                                            className={`form-control ${bulkInvestorErrors?.pullstartdate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e ,"bulkinvestor")} value={bulkInvestorData?.pullstartdate}
                                            max={bulkInvestorData?.pullenddate || undefined}
                                       />
                                    </div>
                                    <div className="form-group">
                                        <label>Statement Pull End Date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="pullenddate" tabindex="2" 
                                            className={`form-control ${bulkInvestorErrors?.pullenddate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e ,"bulkinvestor")} value={bulkInvestorData?.pullenddate}
                                            min={bulkInvestorData?.pullstartdate || undefined }
                                       />
                                    </div>
                                    
                                    <div className="form-group generate">
                                        <label></label>
                                        <button type="button" className="create_btn" onClick={handlePull}>Send Email Statements<span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div>

                                    
                                </div>
                                {/* <hr className="hr"></hr> */}
                                {/* <div className="md-container">
                                    {/* <div className='search mt-4'> */}
                                        {/* <h5 className="title mt-0 mb-0">Bulk investor statement</h5> */}
                                        {/* <button id="" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button> */}
                                    {/* </div> */}
                                        {/* <img src="assets/images/investor-statement.png" class="img w-100" alt=""/> */}
                                        {/* <EditorEdit content={content} setContent={setContent} name = "template" hidePrint = {hidePrint}/> */}
                                {/* </div> */}
                                <hr className="hr"></hr>
                                
                                <div className="row form_section investor_block">
                                <div className='col-md-6'>
                                <h4 class="title mb_10">MAIL BODY</h4>
                                    <div className="investor-editor-container">
                                    <EditorEditNew emailBody={emailBody} setEmailbody={setEmailbody} name = "template" hidePrint = {hidePrint}/>
                                    </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className="flex">
                                    <h4 class="title mb_10">MAIL Statement</h4>
                                
                                    </div>
                                    <div className="investor-editor-container">
                                    <EditorEdit content={content} setContent={setContent} name = "template" hidePrint = {hidePrint}/>
                                    </div>
                                    </div>
                                </div>


                            
                                {/* <hr className="hr"></hr> */}
                          
                                {/* <div className='search mt-4'>
                                    <h5 className="title mt-0 mb-0">STATEMENTS LOG</h5>
                                    
                                    <div className="d-flex align-items-center">
                                        <button id="" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button>
                                        <button id="bulkInvestorExport" type="button" class="border_btn ms-2" onClick={handleExport}>Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                        <button id="" type="button" class="border_btn ms-2">Refresh<span className="material-symbols-outlined">reply</span></button>
                                    </div>
                                </div> */}
                               
                           

                            </div>
                            <div class="tab-pane statements_log" id="statementslog" role="tabpanel">
                               
                          
                                <DataTable 
                                className='termsheetprefills_table'
                                columns={columns}
                                data={statementsLogData}
                                pagination
                                paginationServer
                                // paginationTotalRows={(!searchTerm) ? totalcount : filteredFundData?.length}
                                paginationTotalRows={totalcount}
                                customStyles={customStyles}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                                persistTableHead
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerPageChange}
                                id="prefillPagination"
                                //sortServer={true}  // If you're handling sorting on the server-side, use this prop
                                //onSort={(column, sortDirection) => handleSort(column.selector, sortDirection)}    
                                //onSort={handleSort}
                                // Enable client-side sorting
                                //sortServer={false}
                                /> 
                           

                            </div>

                        </div>
                  
                        </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminInvestorStatements;