import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { deleteEmailManager, getAllEmailsAdmin , getUserPermissionsAdmin ,deleteUserRoleAdmin} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';

const RolesAdmin = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteConfirmation,setDeleteConfirmation] = useState(false);
  const [allroles, setAllRoles] = useState([]);
  const loading = useSelector((state) => state.loaderVisible);
  const [deleteData , setDeleteData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [message , setMessage] = useState('Updated Successfully');

  useEffect(() => {
    dispatch(showLoader());
    getUserRoles();
    //dispatch(hideLoader());
  }, []);

  useEffect(() => {
    console.log("all roles ",allroles)
  },[allroles])

  const getUserRoles = () => { 
    dispatch(showLoader());
    getUserPermissionsAdmin().then(function (response) {
        if (response?.result) {
            setAllRoles(response?.result);
            console.log('get all roles  response---->',response?.result);
            const updatedRoles = response.result.map((sheet) => ({
              "label": sheet?.type,
              "value": sheet?.type,
            }));
    
            console.log('updatedRoles', updatedRoles);
    
            // Use a Set to ensure unique values
            // const uniqueTermSheets = new Set([...termSheet, ...updatedTermSheet]);
    
            // Convert the Set back to an array
            // const uniqueTermSheetsArray = Array.from(uniqueTermSheets);
    
            // Update the state with unique values
            // setTermSheets(uniqueTermSheetsArray);
          }
          dispatch(hideLoader());
    }).catch((err) => {
        dispatch(hideLoader());
        console.error.bind("my account error", err);
    })
  }

  const handleDelete = () => {

        // console.log(deleteConfirmData);
        setShowConfirmation(false);
        let delete_id = deleteData?.roleid;
        dispatch(showLoader());
        console.log('deleteId',delete_id)
        // setPopupOpen(true);
        // setMessage("Template deletion done successfully.");
        // dispatch(hideLoader());
       deleteUserRoleAdmin(delete_id).then(function (response) {
                if (response?.result) {
                    setPopupOpen(true);
                    setMessage("Deleted Successfully.")
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("delete error", err);
            })
  }

  const addRole = () => {
    //console.log('add data',data)
    navigate('/userrolesadmin', { state: { 'mode' : 'add'} });
  };

  const editRole = (data) => {
    console.log('edit data',data)
    navigate('/userrolesadmin', { state: { data , 'mode' : 'edit'} });
  };

  const deleteRole = (data) => {
    console.log(data);
      setShowConfirmation(true);
      setDeleteData(data);
  };

  const handleSearchChange = (e) => {
    console.log('serach term',e.target.value);
    setSearchTerm(e.target.value);
  };

  const filteredTermsheets = allroles.filter((sheet) =>
    sheet?.status != 'ARCHIVED' &&
    sheet?.type?.toLowerCase().includes(searchTerm?.toLowerCase()) 
    //||
    //sheet?.programType?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const columns = [
    {
      name: 'ROLE NAME',
      selector: (row) => {
        const type = row?.type?.toLowerCase();
        return type.charAt(0).toUpperCase() + type.slice(1);
      },
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.type?.toLowerCase();
        const nameB = b?.type.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      width: '40%', // Adjust this percentage to ensure equal width
    },
    {
      name: 'STATUS',
      selector: (row) => row?.status,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.status?.toLowerCase();
        const nameB = b?.status.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      width: '40%', // Adjust this percentage to ensure equal widt
    },
    // {
    //   name: '',
    //   selector: '' ,
    //   sortable: '',
      
    // },

    {
        name: 'ACTIONS',
        cell: (row,index) => (<>
           
          <button id={`editEmail-${index}`} onClick={(e) => { editRole(row); }} className="icon_btn edit">
            <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">edit</span>
          </button>
          <button id={`deleteEmail-${index}`} onClick={(e) => { deleteRole(row); }} className="icon_btn edit">
          <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">delete</span>
        </button></>
        ),
        //width: '50%',// Adjust this percentage to ensure equal width
      },
  ];

  const customStyles = {
    //   headRow: {
	// 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
	// 	},
	// },
    headCells: {
		style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',
		
		},
	},
        table: {
      style: {
        border: '1px solid #CCD8F6', 
      },
    },
  };

  const closePopup = () => {
    setPopupOpen(false);
    getUserRoles();
  };

  const closePopup2 = () => {
    setDeleteConfirmation(false)
    setShowConfirmation(false)
  };

  return (
    <div id="layout-wrapper">
      {loading ? <Loader /> : null}

      {isPopupOpen && (
                <PopupMessage
                    type={'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}

       

      {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={() => handleDelete()}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-role-yes',
                      className: 'btn btn-primary',
                      onClick: handleDelete,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-role-no',
                      className: 'btn btn-light',
                      onClick: closePopup2,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this Role?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
      <MainHeader />
      <SideMenu />

      <div className="main-content">
        <div className="page-content user_management">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">User Roles</h4>
                  
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className='search'>
                    <button id="createEmailManager" onClick={addRole} className="create_btn">Create New Role <span className="material-symbols-outlined icon"> add </span></button>
                      <div className="right">
                        <div className='form-group search_block'>
                          <input id="searchEmail" type="text" className='form-control' placeholder='Search for the Role' value={searchTerm} onChange={handleSearchChange}/>
                          <button id="searchEmailBtn" className="material-symbols-outlined btn">search</button>
                        </div>
                        
                      </div>
                    </div>
                    {filteredTermsheets.length > 0 &&
                      <DataTable className='userrole_table'
                        columns={columns}
                        data={filteredTermsheets}
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Per Page',
                        }}
                      />
                    }
                    {filteredTermsheets?.length == 0 &&
                    <div className="inprogress_page">
                    <span className="material-symbols-outlined icon"> content_paste_search </span>
                    <p>No Roles Found</p>
                </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
};

export default RolesAdmin;