import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { deleteEmailManager, getAllEmailsAdmin} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
import moment from 'moment';

const EmailManager = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteConfirmation,setDeleteConfirmation] = useState(false);
  const [allemails, setAllEmails] = useState([]);
  const loading = useSelector((state) => state.loaderVisible);
  const [deleteData , setDeleteData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [message , setMessage] = useState('Updated Successfully');

  useEffect(() => {
    //dispatch(showLoader());
    getEmails();
    //dispatch(hideLoader());
  }, []);

  useEffect(() => {
    console.log("all term sheets ",allemails)
  },[allemails])

  const getEmails = () => { 
    dispatch(showLoader());
    getAllEmailsAdmin().then(function (response) {
        if (response?.result) {
            setAllEmails(response?.result?.data);
            console.log('response---->',response?.result);
            const updatedEmails = response.result.data.map((sheet) => ({
              "label": sheet?.name,
              "value": sheet?.name,
            }));
    
            console.log('updatedEmails', updatedEmails);
    
            // Use a Set to ensure unique values
            // const uniqueTermSheets = new Set([...termSheet, ...updatedTermSheet]);
    
            // Convert the Set back to an array
            // const uniqueTermSheetsArray = Array.from(uniqueTermSheets);
    
            // Update the state with unique values
            // setTermSheets(uniqueTermSheetsArray);
          }
          dispatch(hideLoader());
    }).catch((err) => {
        dispatch(hideLoader());
        console.error.bind("my account error", err);
    })
  }

  const handleDelete = () => {

        // console.log(deleteConfirmData);
        setShowConfirmation(false);
        let delete_id = deleteData?.templateid;
        dispatch(showLoader());
        console.log('deleteId',delete_id)
        // setPopupOpen(true);
        // setMessage("Template deletion done successfully.");
        // dispatch(hideLoader());
       deleteEmailManager(delete_id).then(function (response) {
                if (response?.result) {
                    setPopupOpen(true);
                    setMessage("Template deletion done successfully.")
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("delete error", err);
            })
  }

  const editEmail = (data) => {
    console.log('data',data)
    navigate('/emailtemplate', { state: { data , 'mode' : 'edit'} });
  };

  const deleteEmail = (data) => {
    console.log(data);
      setShowConfirmation(true);
      setDeleteData(data);
  };

  const handleSearchChange = (e) => {
    console.log('serach term',e.target.value);
    setSearchTerm(e.target.value);
  }

  // const filteredTermsheets = allemails?.filter((sheet) =>
  //   sheet?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) 
  //   //||
  //   //sheet?.programType?.toLowerCase().includes(searchTerm?.toLowerCase())
  // );
  const filteredTermsheets = Array.isArray(allemails)
    ? allemails.filter((sheet) =>
        sheet?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        // ||
        // sheet?.programType?.toLowerCase().includes(searchTerm?.toLowerCase())
      ): 
  [];


  const columns = [
    {
      name: 'TEMPLATE NAME',
      selector: (row) => row?.name,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.name?.toLowerCase();
        const nameB = b?.name?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      width : '30%'
    },
    {
      name: 'Created On',
      selector: (row) => moment.utc(row?.created).format("MM-DD-YYYY") || '',
      sortable: true,
      width : '25%'
      
    },
    {
      name: 'Created By',
      selector: (row) => row?.createdby?.username || '',
      sortable: true,
      width : '25%'
      
    },
    // {
    //   name: '',
    //   selector: '' ,
    //   sortable: '',
      
    // },

    {
        name: 'ACTIONS',
        cell: (row,index) => (<>
          <button id={`editEmail-${index}`} onClick={(e) => { editEmail(row); }} className="icon_btn edit">
            <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">edit</span>
          </button>
          <button id={`deleteEmail-${index}`} onClick={(e) => { deleteEmail(row); }} className="icon_btn edit">
          <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">delete</span>
        </button></>
        ),
        width : '20%'
      },
      
  ];

  const customStyles = {
    //   headRow: {
	// 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
	// 	},
	// },
    headCells: {
		style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',
		
		},
	},
        table: {
      style: {
        border: '1px solid #CCD8F6', 
      },
    },
  };

  const clearSearch = () => {
    setSearchTerm(''); // Clear the search input
    //setCurrentPage(1); // Optionally reset pagination
  };

  const closePopup = () => {
    setPopupOpen(false);
    getEmails();
  };

  const closePopup2 = () => {
    setDeleteConfirmation(false)
    setShowConfirmation(false)
  };

  return (
    <div id="layout-wrapper">
      {loading ? <Loader /> : null}

      {isPopupOpen && (
                <PopupMessage
                    type={'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}

       

      {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={() => handleDelete()}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-email-yes',
                      className: 'btn btn-primary',
                      onClick: handleDelete,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-email-no',
                      className: 'btn btn-light',
                      onClick: closePopup2,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this email template?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
      <MainHeader />
      <SideMenu />

      <div className="main-content">
        <div className="page-content user_management">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Email Manager</h4>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className='search'>
                      <button id="createEmailManager" onClick={(e) => { e.preventDefault(); navigate('/emailtemplate')}} className="create_btn">Create Email Template <span className="material-symbols-outlined icon"> add </span></button>
                      <div className="right">
                        <div className='form-group search_block'>
                          <input id="searchEmail" type="text" className='form-control' placeholder='Search Email Template' value={searchTerm} onChange={handleSearchChange}/>
                          {searchTerm && (
                                <button
                                    id="clearSearchBtn"
                                    className="material-symbols-outlined btn"
                                    onClick={clearSearch}
                                    style={{
                                        marginLeft: '-30px', // Adjust the positioning as needed
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color : '#000'
                                    }}
                                >
                                    close
                                </button>
                            )}
                          <button id="searchEmailBtn" className="material-symbols-outlined btn">search</button>
                        </div>
                        
                      </div>
                    </div>
                    {filteredTermsheets.length > 0 &&
                      <DataTable className='emailmanager_table'
                        columns={columns}
                        data={filteredTermsheets}
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Per Page',
                        }}
                        persistTableHead
                        noDataComponent="No results found" // Show a message when there are no rows
                      />
                    }
                    {filteredTermsheets?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
};

export default EmailManager;